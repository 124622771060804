<h3 class="ui header">
	<i class="table icon"></i>
	<div class="content">
		Entitlement Matrix
		<div class="sub header">Entitlements</div>
	</div>
</h3>
<br />

<div class="ui form">
	<div class="fields">
		<div class="five wide field" app-field [error]="validation | path: 'headquarterId'">
			<label>Headquarter*</label>
			<sm-dropdown class="ui selection dropdown"
				[(value)]="entitlementMatrixModel.headquarterId"
				[options]="headquarterDropdownValues"
				(valueChange)="onHeadquarterValueChange()">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>

		<div *ngIf="entitlementMatrixModel.matrixVersions.length" class="eleven wide field">
			<label>Matrix Version*</label>
			<div class="fields">
				<div class="eight wide field" app-field [error]="validation | path: 'matrixVersionId'">
					<sm-dropdown class="ui selection dropdown"
						[(value)]="entitlementMatrixModel.matrixVersionId"
						[options]="matrixVersionsDropdownValues"
						(valueChange)="onMatrixVersionValueChange()">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="inline eight wide field" style="padding-top: 0.4rem;">
					<i class="circle icon"
						style="margin-right: 0.25rem; font-size: 1.7em; vertical-align: middle"
						[ngClass]="selectedHeadquarter.generateDependentRations ? 'check green' : 'times red'">
					</i>
					<label>Generate Dependent Rations</label>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="ui divider"></div>

<div class="ui stackable grid">
	<div class="row">
		<div class="column">

			<button *ngIf="!hasDrafts" class="ui basic button"
				(click)="openAddMatrixModal()">
				<i class="plus icon"></i>
				Add Matrix
			</button>

			<button *ngIf="entitlementMatrixModel.matrixVersionId" class="ui basic button"
				(click)="openAddMatrixModal(true)">
				<i class="edit icon"></i>
				Edit Matrix
			</button>

			<button *ngIf="entitlementMatrixModel.matrixVersionId && !isActiveMatrix" class="ui basic button"
				(click)="deleteMatrixModal.toggle()">
				<i class="trash alternate icon"></i>
				Delete Matrix
			</button>

			<button class="ui basic button"
				[disabled]="!entitlementMatrixModel.matrixVersionId"
				(click)="administerMatrix()">
				<i class="tools icon"></i>
				Administer Matrix
			</button>

			<button class="ui basic button"
				[disabled]="!entitlementMatrixModel.matrixVersionId"
				(click)="bulkAdvanceSettingsModal.toggle()">
				<i class="sliders horizontal icon"></i>
				Bulk Advance Settings
			</button>

			<button *ngIf="entitlementMatrixModel.matrixVersionId" class="ui right floated basic button"
				[disabled]="exportButtonLoading"
				[class.loading]="exportButtonLoading"
				(click)="exportMatrix()">
				<i class="file download icon"></i>
				Export Matrix
			</button>

			<button class="ui right floated basic button"
				(click)="openImportMatrixModal()">
				<i class="file upload icon"></i>
				Import Matrix
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<div class="ui icon input" style="margin-right: 1em">
				<i class="search link icon" (click)="search()"></i>
				<input type="text"
					placeholder="Search..."
					[(ngModel)]="pagedQueryModel.searchBy"
					(keydown.enter)="search()"
					app-trim>
			</div>

			<button class="ui basic button"
				[class.active]="currentView === 'Codes'"
				(click)="changeView('Codes')">
				<i class="cubes icon"></i>
				Code View
			</button>
			<button class="ui basic button"
				[class.active]="currentView === 'Types'"
				(click)="changeView('Types')">
				<i class="cube icon"></i>
				Type View
			</button>
			<button *ngIf="entitlementMatrixModel.matrixVersions.length && !expiresToday" class="ui right floated primary button"
				(click)="currentView === 'Codes' ? openAddMatrixElementModal() : typeAssociationModal.openAddTypeAssociationModal()">
				<i class="plus icon"></i> {{currentView === 'Codes' ? 'Add Entitlement Matrix Element' : 'Add Type Association' }}
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="entitlementMatrixItemTemplate"
				[data]="entitlementMatrixTableData"
				[dataReady]="tableDataReady"
				[columns]="currentView === 'Codes' ? entitlementMatrixCodeColumns : entitlementMatrixTypeColumns"
				[noSearch]="true"
				[useServerPagination]="true"
				[pageSize]="pagination.pageSize"
				[currentPage]="pagination.currentPage"
				[totalPages]="pagination.totalPages"
				(pageChangeEvent)="pageChange($event)"
				(sortChangeEvent)="sortChange($event)">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #entitlementMatrixItemTemplate let-item="item">
	<tr *ngIf="currentView === 'Codes'">
		<td style="text-align: center;">
			<a *ngIf="!expiresToday" href="javascript:void(0);" (click)="openAddMatrixElementModal(item)">{{item.fullCode}}</a>
			<ng-container *ngIf="expiresToday">{{item.fullCode}}</ng-container>
		</td>
		<td>{{item.group}}</td>
		<td>{{item.description}}</td>
		<td style="text-align: center;">
			<a *ngIf="!expiresToday" href="javascript:void(0);" (click)="openDisableMatrixElementModal(item)">Disable</a>
		</td>
	</tr>

	<tr *ngIf="currentView === 'Types'">
		<td style="text-align: center;">
			<a *ngIf="item.entitlementMeasurementUnitAssociationId && !expiresToday" href="javascript:void(0);" (click)="typeAssociationModal.openAddTypeAssociationModal(item)">{{item.entitlementTypeName}}</a>
			<ng-container *ngIf="!item.entitlementMeasurementUnitAssociationId || expiresToday">{{item.entitlementTypeName}}</ng-container>
		</td>
		<td style="text-align: center;">{{item.entitlementMeasurementUnitName | dash}}</td>
		<td style="text-align: center;">{{item.period | dash }}</td>
		<td>{{item.entitlementMeasurementUnitDescription | dash }}</td>
		<td style="text-align: center;">
			<a *ngIf="!expiresToday" href="javascript:void(0);" (click)="openDisableMatrixElementModal(item)">Disable</a>
		</td>
	</tr>
</ng-template>

<sm-modal #addMatrixElementModal [isLoading]="modalIsLoading" [isSmall]="true" (closeEvent)="closeMatrixElementModal()">
	<ng-container *ngIf="!isEditModal" header>Add Entitlement Matrix Element</ng-container>
	<ng-container *ngIf="isEditModal" header>Modify Code: {{selectedEntitlementCode.fullCode}}</ng-container>

	<ng-container content>
		<div class="ui three top attached steps">
			<div class="step" [class.active]="currentStep === 2"
				[class.link]="isEditModal"
				(click)="isEditModal ? currentStep = 2 : null">
				<i class="cubes icon"></i>
				<div class="content">
					<div class="title">Step 1</div>
					<div class="description">Entitlement Code Info</div>
				</div>
			</div>
			<div class="step" [class.active]="currentStep === 3"
				[class.link]="isEditModal"
				(click)="isEditModal ? currentStep = 3 : null">
				<i class="cube icon"></i>
				<div class="content">
					<div class="title">Step 2</div>
					<div class="description">Entitlement Type Association</div>
				</div>
			</div>
		</div>
		<div class="ui attached segment">
			<div *ngIf="currentStep === 2" class="ui form">
				<div *ngIf="!isEditModal" class="field" app-field [error]="validation | path: 'entitlementCodeId'">
					<label>Code*</label>
					<sm-dropdown class="ui selection dropdown"
						[(value)]="entitlementMatrixModel.entitlementCodeId"
						[options]="entitlementCodesDropdownValues"
						(valueChange)="onEntitlementCodeValueChange()">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field" app-field [error]="validation | path: 'entitlementCodeGroup'">
					<label>Group*</label>
					<input type="text" [(ngModel)]="entitlementMatrixModel.entitlementCodeGroup" app-trim>
				</div>

				<div class="field" app-field [error]="validation | path: 'entitlementCodeDescription'">
					<label>Description*</label>
					<textarea style="height: 135px; resize: none;" [(ngModel)]="entitlementMatrixModel.entitlementCodeDescription" app-trim></textarea>
				</div>

				<h4>All fields marked with * are mandatory</h4>
			</div>

			<div *ngIf="currentStep === 3" class="ui form">
				<table class="ui unstackable celled short scrolling table">
					<thead>
						<tr style="text-align: center;">
							<th class="five wide">Rationed Items</th>
							<th class="six wide">Amount</th>
							<th class="five wide">Unit</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngIf="!rationedEntitlementTypeAssociationItems.length" style="text-align: center;">
							<td class="five wide">-</td>
							<td class="six wide">-</td>
							<td class="five wide">-</td>
						</tr>
						<tr style="text-align: center;" *ngFor="let item of rationedEntitlementTypeAssociationItems">
							<td class="five wide">
								<div class="ui checkbox">
									<input type="checkbox" id="rationedItem-{{item.id}}" [(ngModel)]="item.checked" (change)="setDefaultAmount(item)">
									<label for="rationedItem-{{item.id}}">{{item.entitlementTypeName}}</label>
								</div>
							</td>
							<td class="six wide">
								<div class="field" app-field [error]="amountValidations[item.id] | path: 'amount'">
									<input style="max-width: 85px;"
										type="number"
										min="0"
										[(ngModel)]="item.amount"
										(change)="onAmountValueChange(item)">
								</div>
							</td>
							<td class="five wide">{{item.unit}}</td>
						</tr>
					</tbody>
				</table>

				<table class="ui unstackable celled short scrolling table">
					<thead>
						<tr style="text-align: center;">
							<th>Unrationed Items</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngIf="!nonRationedEntitlementTypeAssociationItems.length" style="text-align: center;">
							<td>-</td>
						</tr>
						<tr style="text-align: center;" *ngFor="let item of nonRationedEntitlementTypeAssociationItems">
							<td>
								<div class="ui checkbox">
									<input type="checkbox" id="nonRationedItem-{{item.id}}" [(ngModel)]="item.checked" (change)="atleastOneEntitlementTypeCheck()">
									<label for="nonRationedItem-{{item.id}}">{{item.entitlementTypeName}}</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="currentStep === 2 ? closeMatrixElementModal() : back()">
			{{currentStep === 2 ? 'Cancel' : 'Back'}}
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="currentStep === 3 ? !atleastOneChecked || !validAmounts : !isValid"
			(click)="currentStep === 2 ? next() : saveMatrixElement()">
			{{currentStep === 2 ? 'Next' : 'Save'}}
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>

<app-type-association-modal [headquarterId]="entitlementMatrixModel.headquarterId"
	[matrixVersionId]="entitlementMatrixModel.matrixVersionId"
	[existingEntitlementTypeIds]="existingEntitlementTypeIds"
	[tableData]="entitlementMatrixTableData"
	(refreshTableData)="refreshTableData()">
</app-type-association-modal>

<sm-modal #matrixElementDisableModal [isLoading]="modalIsLoading" [isTiny]="true">
	<ng-container *ngIf="currentView === 'Codes'" header>Disable Code: {{selectedEntitlementCode.fullCode}}</ng-container>
	<ng-container *ngIf="currentView === 'Types'" header>Disable Type: {{selectedEntitlementType.entitlementTypeName}}</ng-container>

	<ng-container *ngIf="currentView === 'Codes'" content>
		<p *ngIf="!impactedActiveCardsBatches.length">Are you sure you want to disable code <b>{{selectedEntitlementCode.fullCode}}</b>?</p>

		<div *ngIf="impactedActiveCardsBatches.length" class="ui grid">
			<div class="row">
				<div class="three wide column" style="align-content:center; text-align: center;">
					<i class="huge red exclamation triangle icon"></i>
				</div>
				<div class="thirteen wide column">
					<p>Are you sure you want to disable code <b>{{selectedEntitlementCode.fullCode}}</b>?</p>
					<p><span class="ui inverted red text">Warning!</span> If you choose to proceed with this action, the following active cards will be impacted!</p>
				</div>
			</div>

			<div class="ui divider"></div>
		</div>

		<div *ngIf="impactedActiveCardsBatches.length" class="ui grid" style="max-height: 510px; overflow-y: auto;">
			<div *ngFor="let batch of impactedActiveCardsBatches" class="three column centered row">
				<div *ngFor="let column of batch" class="column">
					<div class="ui raised segment">
						<div class="ui bulleted list">
							<div *ngFor="let card of column" class="item">{{card}}</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	</ng-container>

	<ng-container *ngIf="currentView === 'Types'" content>
		<p>Are you sure you want to disable type <b>{{selectedEntitlementType.entitlementTypeName}}</b>?</p>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="matrixElementDisableModal.toggle()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="disable()">
			Disable
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal #addMatrixModal [isLoading]="modalIsLoading" [isMini]="true" (closeEvent)="closeAddMatrixModal()">
	<ng-container *ngIf="!isEditMatrixVersionModal" header>Add new matrix for <span class="ui purple text">{{selectedHeadquarter.name}}</span> HQ</ng-container>
	<ng-container *ngIf="isEditMatrixVersionModal" header>Modify matrix for <span class="ui purple text">{{selectedHeadquarter.name}}</span> HQ</ng-container>

	<ng-container content>
		<div class="ui form">
			<div *ngIf="!isEditMatrixVersionModal || isEditMatrixVersionModal && !isActiveMatrix" class="field" app-field [error]="validation | path: 'matrixVersionStartDate'">
				<label>Start Date*</label>
				<sm-calendar class="ui calendar"
					[settings]="calendarSettings"
					[(value)]="entitlementMatrixModel.matrixVersionStartDate"
					[maxValue]="entitlementMatrixModel.matrixVersionEndDate"
					(valueChange)="onMatrixVersionStartDate()">
					<div class="ui fluid input left icon">
						<i class="calendar icon"></i>
						<input type="text" placeholder="Date" />
					</div>
				</sm-calendar>
			</div>

			<div *ngIf="isEditMatrixVersionModal" class="field">
				<div class="ui checkbox">
					<input type="checkbox" id="endOfTimeMatrixVersion" [(ngModel)]="entitlementMatrixModel.endOfTime" (change)="setEndOfTime()">
					<label for="endOfTimeMatrixVersion">End of time</label>
				</div>
			</div>

			<div *ngIf="isEditMatrixVersionModal && !entitlementMatrixModel.endOfTime" class="field" app-field [error]="validation | path: 'matrixVersionEndDate'">
				<label>End Date</label>
				<sm-calendar class="ui calendar"
					[settings]="calendarSettings"
					[(value)]="entitlementMatrixModel.matrixVersionEndDate"
					[minValue]="isEditMatrixVersionModal ? minEndDateValue : entitlementMatrixModel.matrixVersionStartDate">
					<div class="ui fluid input left icon">
						<i class="calendar icon"></i>
						<input type="text" placeholder="Date" />
					</div>
				</sm-calendar>
			</div>

			<div *ngIf="!isEditMatrixVersionModal" class="field">
				<div class="ui checkbox">
					<input type="checkbox" id="blankMatrixVersion" [(ngModel)]="entitlementMatrixModel.createBlankMatrixVersion">
					<label for="blankMatrixVersion">Blank Matrix</label>
				</div>
			</div>
		</div>

		<ng-container *ngIf="isEditMatrixVersionModal && errors.length">
			<div class="ui divider"></div>
			<div class="ui grid">
				<div class="row">
					<div class="three wide column" style="align-content:center; text-align: center;">
						<i class="huge red exclamation triangle icon"></i>
					</div>
					<div class="thirteen wide column">
						<p><span class="ui inverted red text">Warning!</span> If you choose to proceed with this action, the following draft matrix will be deleted!</p>
					</div>
				</div>
				<div class="row">
					<div class="column">
						<div class="ui raised segment">
							<div class="ui bulleted list">
								<div class="item">{{errors[0]}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="closeAddMatrixModal()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="!isForceSaveError ? saveMatrix() : saveMatrix(true)">
			{{ !isForceSaveError ? 'Save' : 'Force Save'}}
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal #bulkAdvanceSettingsModal (closeEvent)="bulkAdvanceSettings.resetSetting()">
	<ng-container header>
		Edit bulk advance settings for <span class="ui purple text">{{selectedHeadquarter.name}}</span> HQ
	</ng-container>

	<ng-container content>
		<app-bulk-advance-settings #bulkAdvanceSettings [headquarterId]="entitlementMatrixModel.headquarterId" />
	</ng-container>
</sm-modal>

<sm-modal #deleteMatrixModal [isLoading]="modalIsLoading" [isTiny]="true">
	<ng-container header>Delete entitlement matrix</ng-container>

	<ng-container content>
		<p>Are you sure you want to delete Entitlement Matrix <b>{{hqMatrixVersionFormat}}</b> for <b>{{selectedHeadquarter.name}}</b> HQ?</p>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="deleteMatrixModal.toggle()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="deleteMatrix()">
			Yes
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal #importMatrixModal [isLoading]="modalIsLoading" [isTiny]="true" (closeEvent)="closeImportModal()">

	<ng-container header>Import matrix for <span class="ui purple text">{{selectedHeadquarter.name}}</span> HQ</ng-container>

	<ng-container content>
		<div class="ui grid">
			<div class="row">
				<div class="three wide column" style="align-content:center; text-align: center;">
					<i class="huge red exclamation triangle icon"></i>
				</div>
				<div class="thirteen wide column">
					<p>Are you sure you want to import an Entitlement Matrix for the current selection?</p>
					<p><span class="ui inverted red text">Warning!</span> If you choose to proceed with this action, the selected matrix <b>{{hqMatrixVersionFormat}}</b> will be overwritten!</p>
				</div>
			</div>

			<div class="column">
				<input #fileUpload type="file" class="file-input" (change)="onFileSelected($event)">
			</div>
		</div>

		<app-error-message [errors]="importFileErrors"></app-error-message>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="closeImportModal()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isImportValid"
			(click)="importMatrix()">
			Save
			<i class="check icon"></i>
		</button>
	</ng-container>

</sm-modal>
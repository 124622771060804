<h3 class="ui header">
	<i class="tape icon"></i>
	<div class="content">
		Entitlement Units
		<div class="sub header">Entitlements</div>
	</div>
</h3>
<br />

<div class="ui stackable  grid">
	<div class="row">
		<div class="three wide column">
			<div class="ui icon fluid input">
				<i class="search link icon" (click)="search()"></i>
				<input type="text"
					placeholder="Search..."
					[(ngModel)]="pagedQueryModel.searchBy"
					(keydown.enter)="search()">
			</div>
		</div>
		<div class="thirteen wide column">
			<button class="ui right floated primary button"
				(click)="openAddUnitModal()">
				<i class="plus icon"></i>Add Entitlement Unit
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="entitlementUnitItemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true"
				[useServerPagination]="true"
				[pageSize]="pagination.pageSize"
				[currentPage]="pagination.currentPage"
				[totalPages]="pagination.totalPages"
				(pageChangeEvent)="pageChange($event)"
				(sortChangeEvent)="sortChange($event)">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #entitlementUnitItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>
			<a href="javascript:void(0);" [ngStyle]="{'color': item.disabled ? 'red' : null}" (click)="onUserAction(item, 'Edit')">{{item.name}}</a>
		</td>
		<td>
			<a href="javascript:void(0);" (click)="onUserAction(item, item.action)">{{item.action}}</a>
		</td>
	</tr>
</ng-template>

<sm-modal [isLoading]="modalIsLoading" #entitlementUnitsModal (closeEvent)="close('entitlementUnitsModal')" [isTiny]="true">
	<ng-container header>{{editModal ? 'Modify Entitlement Unit: ' + entitlementUnit.name : 'Add Entitlement Unit'}}</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="validation | path: 'name'">
				<label>Unit Name*</label>
				<input type="text" [(ngModel)]="entitlementUnit.name" app-trim>
			</div>
		</div>

		<app-error-message [errors]="errors"></app-error-message>

		<h4>All fields marked with * are mandatory</h4>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close('entitlementUnitsModal')">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="save()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal [isLoading]="modalIsLoading" #entitlementUnitConfimationModal [isTiny]="true">
	<ng-container *ngIf="action==='Enable'" header>Enable entitlement unit: {{entitlementUnit.name}}</ng-container>
	<ng-container *ngIf="action==='Disable'" header>Disable entitlement unit: {{entitlementUnit.name}}</ng-container>

	<ng-container *ngIf="action==='Enable'" content>
		<p>Are you sure you want to enable Entitlement Unit: <b>{{entitlementUnit.name}}</b>?</p>

		<app-error-message [errors]="errors"></app-error-message>
	</ng-container>

	<ng-container *ngIf="action==='Disable'" content>
		<p>Are you sure you want to disable Entitlement Unit: <b>{{entitlementUnit.name}}</b>?</p>
		<div class="ui grid">
			<div class="two wide column">
				<i class="huge red exclamation triangle icon"></i>
			</div>
			<div class="fourteen wide column">
				<p><span class="ui inverted red text">Warning!</span> If you remove an entitlement measurement unit, it will be deleted from all entitlement matrixes available!</p>
			</div>
		</div>

		<app-error-message [errors]="errors"></app-error-message>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close('entitlementUnitConfimationModal')">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="enableOrDisable()">
			{{ action }}
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>
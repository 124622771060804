<div class="ui fluid search">
	<div class="ui icon input"
		style="width: 100%;">

		<div class="right aligned floating ui teal label" *ngIf="totalResults">{{totalResults}}</div>

		<input class="prompt"
			type="text"
			placeholder="Search (min 3 characters)"
			style="border-radius: 2px;"
			value="{{search}}" />

		<i class="search icon"></i>
	</div>

	<div class="results" style="max-height: 300px; overflow:auto"></div>
</div>
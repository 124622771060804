import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent, ColumnSortation, PagedQueryParameter, PaginationModel, TableColumn } from '@nstep-common/core';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import {
	AssociationItem,
	AssociationItemValidator,
	CreateMatrixDto,
	EntitlementAssignmentExtendedDto,
	EntitlementAssignmentModel,
	EntitlementCodeDto,
	EntitlementCodeGroupDto,
	EntitlementMatrixGroupValidations,
	EntitlementMatrixModel,
	EntitlementMatrixModelValidator,
	EntitlementMatrixVersionHistoryDto,
	MainMatrixView,
	EntitlementTypeAssociationDto,
	EntitlementTypeAssociationItem,
	HeadquarterExtendedModel,
	MatrixVersionHistoryDto,
	UpdateMatrixDto,
	EntitlementMatrixView,
	EntitlementMatrixViewChangeModel,
	TypeAssociationModalComponent,
	RationedType
} from '@nstep-internal/pages';
import { AssignmentService, EntitlementMatrixService, EntitlementTypeAssociationService, HeadquarterService } from '@nstep-internal/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';
import { forkJoin, Observable } from 'rxjs';

@Component({
	selector: 'app-main-matrix',
	templateUrl: './main-matrix.component.html'
})
export class MainMatrixComponent extends BaseComponent implements OnInit {

	@Output() dataPropagationChange = new EventEmitter<EntitlementMatrixViewChangeModel>();

	@ViewChild('addMatrixModal') addMatrixModal!: ModalComponent;
	@ViewChild('deleteMatrixModal') deleteMatrixModal!: ModalComponent;

	@ViewChild('matrixElementDisableModal') matrixElementDisableModal!: ModalComponent;
	@ViewChild('addMatrixElementModal') addMatrixElementModal!: ModalComponent;
	@ViewChild('importMatrixModal') importMatrixModal!: ModalComponent;
	@ViewChild('fileUpload') fileUpload!: ElementRef;

	@ViewChild(TypeAssociationModalComponent) typeAssociationModal!: TypeAssociationModalComponent;

	errors: string[] = [];
	isForceSaveError = false;

	currentView = MainMatrixView.Codes;
	isEditModal = false;
	currentStep = EntitlementMatrixGroupValidations.MainPage;
	modalIsLoading = false;
	isEditMatrixVersionModal = false;

	allHeadquarterMatrixVersions: HeadquarterExtendedModel[] = [];
	selectedHeadquarter: HeadquarterExtendedModel = {} as HeadquarterExtendedModel;
	headquarterDropdownValues: DropdownOption[] = [];

	selectedMatrixVersion: MatrixVersionHistoryDto = {} as MatrixVersionHistoryDto;
	isActiveMatrix = false;
	expiresToday = false;
	hasDrafts = false;
	hqMatrixVersionFormat = '';
	matrixVersionsDropdownValues: DropdownOption[] = [];

	unassignedEntitlementCodes: EntitlementCodeDto[] = [];
	selectedUnassignedEntitlementCode: EntitlementCodeDto = {} as EntitlementCodeDto;

	entitlementCodesDropdownValues: DropdownOption[] = [];

	existingEntitlementTypeIds: number[] = [];

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: 'fullCode',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	entitlementMatrixTableData: any[] = [];
	tableDataReady = false;

	entitlementMatrixCodeColumns: TableColumn[] = [
		{ name: 'Code', key: 'fullCode', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Group', key: 'group', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Description', key: 'description', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Action', isCellCentered: true, isHeaderCentered: true }
	];

	entitlementMatrixTypeColumns: TableColumn[] = [
		{ name: 'Type', key: 'entitlementTypeName', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Unit Of Measure', key: 'entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Period', key: 'periodCycle', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Unit Description', key: 'entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitDescription', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Action', isCellCentered: true, isHeaderCentered: true }
	];

	endOfTimeDate = '9999-12-31';

	minEndDateValue: Date | null = null;
	today = new Date(new Date().toDateString());

	calendarSettings = {
		type: 'date',
		minDate: new Date(this.today.setDate(this.today.getDate() + 1))
	};

	selectedEntitlementCode: EntitlementCodeDto = {} as EntitlementCodeDto;
	selectedEntitlementType: EntitlementTypeAssociationItem = {} as EntitlementTypeAssociationItem;
	impactedActiveCardsBatches: string[][][] = [];

	rationedEntitlementTypeAssociationItems: AssociationItem[] = [];
	nonRationedEntitlementTypeAssociationItems: AssociationItem[] = [];

	fileName = '';
	importFile: File | null = null;
	isImportValid: boolean = false;
	importFileErrors: string[] = [];
	exportButtonLoading: boolean = false;

	validation: ValidationErrors<EntitlementMatrixModel> = {};
	isValid: boolean = false;

	entitlementMatrixModel: EntitlementMatrixModel = createProxy(new EntitlementMatrixModel(), {
		set: () => this.validate(this.entitlementMatrixModel)
	});

	validate(value: EntitlementMatrixModel): void {
		const validator = new EntitlementMatrixModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	amountValidations: { [id: number]: ValidationErrors<AssociationItem> } = {};
	validAmounts = true;
	atleastOneChecked = false;

	validateAmount(value: AssociationItem): void {
		const validator = new AssociationItemValidator();
		this.amountValidations[value.id] = validator.validate(value);
		this.validAmounts = Object.entries(this.amountValidations).every(([, value]) => Object.keys(value).length === 0);
	}

	constructor(private headquarterService: HeadquarterService,
		private assignmentService: AssignmentService,
		public entitlementMatrixService: EntitlementMatrixService,
		private entitlementTypeAssociationService: EntitlementTypeAssociationService) {
		super();
	}

	ngOnInit(): void {
		this.entitlementMatrixModel.groupPropertiesValidation = EntitlementMatrixGroupValidations.MainPage;
		this.entitlementMatrixModel.headquarterId = null;
		this.entitlementMatrixModel.matrixVersionId = null;
		this.entitlementMatrixModel.matrixVersions = [];

		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];

		this.getHeadquartersEx();
	}

	private getHeadquartersEx(): void {
		this.subscriptions.push(
			this.headquarterService.getHeadquartersEx().subscribe({
				next: response => {

					this.allHeadquarterMatrixVersions = response;
					this.selectedHeadquarter = this.allHeadquarterMatrixVersions[0];

					this.headquarterDropdownValues = chain(this.allHeadquarterMatrixVersions)
						.map(e => new DropdownOption({
							value: e.id,
							name: e.name
						}))
						.value();

					this.entitlementMatrixModel.headquarterId = this.selectedHeadquarter.id;

					this.populateMatrixVersions();
				}
			})
		);
	}

	search(): void {
		if (this.entitlementMatrixModel.matrixVersions.length) this.initializeTable();
	}

	onHeadquarterValueChange(): void {
		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];

		this.selectedHeadquarter = this.allHeadquarterMatrixVersions.find(d => d.id === this.entitlementMatrixModel.headquarterId)!;

		this.populateMatrixVersions();
	}

	populateMatrixVersions(matrix: EntitlementMatrixVersionHistoryDto | null = null): void {
		this.hasDrafts = false;
		this.entitlementMatrixModel.matrixVersions = [];

		if (this.selectedHeadquarter.matrixVersion) this.entitlementMatrixModel.matrixVersions.push(this.selectedHeadquarter.matrixVersion);

		if (this.selectedHeadquarter.futureMatrixes) {

			this.hasDrafts = !!this.selectedHeadquarter.futureMatrixes.length;

			this.selectedHeadquarter.futureMatrixes.forEach(fm => {
				this.entitlementMatrixModel.matrixVersions.push(fm);
			});
		}

		this.matrixVersionsDropdownValues = chain(this.entitlementMatrixModel.matrixVersions)
			.orderBy(e => e.startDate, 'desc')
			.map(e => new DropdownOption({
				value: e.id,
				name: this.entitlementMatrixService.getHeadquarterMatrixVersionFormat(e),
				icon: this.isMatrixActive(e) ? 'green exclamation' : null,
				description: this.isMatrixActive(e) ? 'Currently Active' : null
			}))
			.value();

		if (matrix) {
			this.entitlementMatrixModel.matrixVersionId = matrix.id;
			this.selectedMatrixVersion = this.entitlementMatrixModel.matrixVersions.find(d => d.id === matrix.id)!;
			this.isActiveMatrix = this.isMatrixActive(this.selectedMatrixVersion);
			this.hqMatrixVersionFormat = this.entitlementMatrixService.getHeadquarterMatrixVersionFormat(this.selectedMatrixVersion);

			this.initializeTable();
		}
		else if (!matrix && this.entitlementMatrixModel.matrixVersions.length) {
			this.selectedMatrixVersion = this.entitlementMatrixModel.matrixVersions[0];
			this.entitlementMatrixModel.matrixVersionId = this.entitlementMatrixModel.matrixVersions[0].id;
			this.isActiveMatrix = this.isMatrixActive(this.selectedMatrixVersion);
			this.hqMatrixVersionFormat = this.entitlementMatrixService.getHeadquarterMatrixVersionFormat(this.selectedMatrixVersion);

			this.initializeTable();
		}
		else {
			this.entitlementMatrixModel.matrixVersionId = null;
			this.selectedMatrixVersion = {} as MatrixVersionHistoryDto;
			this.isActiveMatrix = this.isMatrixActive(this.selectedMatrixVersion);
			this.hqMatrixVersionFormat = this.entitlementMatrixService.getHeadquarterMatrixVersionFormat(this.selectedMatrixVersion);

			this.tableDataReady = true;
			toast('Warning', `${this.selectedHeadquarter.name} HQ does not have a matrix!`, 'orange');
		}
	}

	initializeTable(): void {
		if (this.currentView === MainMatrixView.Codes) {
			this.subscriptions.push(
				this.assignmentService.getCodes(this.entitlementMatrixModel.matrixVersionId!, this.pagedQueryModel).subscribe({
					next: response => {

						this.pagination = response.page;
						this.entitlementMatrixTableData = response.results;

						this.tableDataReady = true;
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.assignmentService.getTypes(this.entitlementMatrixModel.matrixVersionId!, this.pagedQueryModel).subscribe({
					next: response => {

						this.existingEntitlementTypeIds = response.results.flatMap(d => d.entitlementTypeId);

						this.pagination = response.page;
						this.entitlementMatrixTableData = chain(response.results)
							.map(e => {

								let period;
								if (e.rationingTypeId == 1) {
									switch (e.entitlementIssuanceTypeId) {
										case 1:
											period = e.periodCycle && e.periodCycle < 0 ? 'Once' : e.periodCycle === 0 ? 'Adjusted by Operator' : e.periodCycle;
											break;
										case 2:
											const periodCycle = this.assignmentService.periodCycles.find(p => p.Value === e.periodCycle);
											if (periodCycle) period = periodCycle.Text;
											break;
									}
								}

								return new EntitlementTypeAssociationItem({
									id: e.id,
									matrixVersionId: e.matrixVersionId,
									entitlementTypeId: e.entitlementTypeId,
									entitlementTypeName: e.entitlementTypeName,
									rationingTypeId: e.rationingTypeId,
									measurementUnitId: e.entitlementMeasurementUnitsAssociation
										? e.entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitId
										: null,
									entitlementMeasurementUnitAssociationId: e.entitlementMeasurementUnitAssociationId,
									entitlementMeasurementUnitDescription: e.entitlementMeasurementUnitsAssociation
										? e.entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitDescription
										: null,
									entitlementMeasurementUnitName: e.entitlementMeasurementUnitsAssociation
										? e.entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitName
										: null,
									entitlementIssuanceTypeId: e.entitlementIssuanceTypeId,
									periodCycle: e.periodCycle,
									periodsIssuedInAdvance: e.periodsIssuedInAdvance,
									periodsValid: e.periodsValid,
									startDate: e.startDate,
									endDate: e.endDate,
									period: period,
									isBuyable: e.isBuyable,
									isNotProRated: e.isNotProRated
								});
							})
							.value();

						this.tableDataReady = true;
					}
				})
			);
		}

	}

	onMatrixVersionValueChange(): void {
		this.selectedMatrixVersion = this.entitlementMatrixModel.matrixVersions.find(d => d.id === this.entitlementMatrixModel.matrixVersionId)!;

		this.isActiveMatrix = this.isMatrixActive(this.selectedMatrixVersion);
		this.hqMatrixVersionFormat = this.entitlementMatrixService.getHeadquarterMatrixVersionFormat(this.selectedMatrixVersion);

		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];
		this.initializeTable();
	}

	openAddMatrixModal(isEdit: boolean = false): void {
		this.errors = [];
		this.isForceSaveError = false;

		this.isEditMatrixVersionModal = isEdit;
		this.entitlementMatrixModel.groupPropertiesValidation = EntitlementMatrixGroupValidations.AddMatrix;

		if (!this.isEditMatrixVersionModal) {
			this.entitlementMatrixModel.matrixVersionStartDate = null;
			this.entitlementMatrixModel.matrixVersionEndDate = null;
			this.entitlementMatrixModel.endOfTime = true;
		} else {
			this.entitlementMatrixModel.matrixVersionStartDate = this.selectedMatrixVersion!.startDate;
			this.entitlementMatrixModel.endOfTime = formatDate(this.selectedMatrixVersion!.endDate, 'yyyy-MM-dd', 'en') === this.endOfTimeDate;
			this.entitlementMatrixModel.matrixVersionEndDate = this.entitlementMatrixModel.endOfTime ? null : this.selectedMatrixVersion!.endDate;

			this.minEndDateValue = new Date(new Date().toDateString());
		}

		this.addMatrixModal.toggle();
	}

	administerMatrix(): void {
		this.dataPropagationChange.emit(new EntitlementMatrixViewChangeModel({
			entitlementMatrixView: EntitlementMatrixView.MatrixAdministration,
			headquarterId: this.selectedHeadquarter.id,
			headquarterName: this.selectedHeadquarter.name,
			selectedMatrixVersionId: this.entitlementMatrixModel.matrixVersionId,
			matrixVersionIsActive: this.isMatrixActive(this.selectedMatrixVersion),
			matrixVersionExpiresToday: this.expiresToday,
			generateDependentRations: this.selectedHeadquarter.generateDependentRations
		}));
	}

	closeAddMatrixModal(): void {
		this.entitlementMatrixModel.groupPropertiesValidation = EntitlementMatrixGroupValidations.MainPage;
		this.addMatrixModal.toggle();
	}

	onMatrixVersionStartDate(): void {
		if (this.entitlementMatrixModel.matrixVersionEndDate && this.entitlementMatrixModel.matrixVersionStartDate && this.entitlementMatrixModel.matrixVersionEndDate < this.entitlementMatrixModel.matrixVersionStartDate) {
			this.entitlementMatrixModel.matrixVersionEndDate = this.entitlementMatrixModel.matrixVersionStartDate;
		}
	}

	setEndOfTime(): void {
		if (this.entitlementMatrixModel.endOfTime) this.entitlementMatrixModel.matrixVersionEndDate = null;
	}

	saveMatrix(forceSave: boolean = false): void {

		this.modalIsLoading = true;

		switch (this.isEditMatrixVersionModal) {
			case false:
				this.addEntitlementMatrix();
				break;
			case true:
				this.editEntitlementMatrix(forceSave);
				break;
		}
	}

	exportMatrix(): void {
		this.exportButtonLoading = true;

		this.subscriptions.push(
			this.entitlementMatrixService.exportEntitlementMatrix(this.entitlementMatrixModel.matrixVersionId!).subscribe({
				next: response => {
					const blob: Blob = response.body as Blob;
					const fileName = response.headers.get('Content-Disposition')?.replace(/['"]+/g, '').split(';')[1].trim().split('=')[1];

					const anchor = document.createElement('a');
					anchor.download = fileName;
					anchor.href = window.URL.createObjectURL(blob);
					anchor.click();

					toast('Success', 'File exported successfully!', 'green');
				},
				error: () => {
					toast('Error', 'Failed to export file!', 'red');
				},
				complete: () => {
					this.exportButtonLoading = false;
				}
			})
		);
	}

	openImportMatrixModal(): void {
		this.fileUpload.nativeElement.value = null;
		this.importFile = null;
		this.isImportValid = false;

		this.importMatrixModal.toggle();
	}

	importMatrix(): void {

		if (this.importFile === null) {
			toast('Error', 'No file has been selected', 'red');
		}

		this.importFileErrors = [];
		this.modalIsLoading = true;

		const formData = new FormData();
		formData.append('file', this.importFile!);

		this.subscriptions.push(
			this.entitlementMatrixService.importEntitlementMatrix(formData, this.entitlementMatrixModel.matrixVersionId!).subscribe({
				next: matrixResponse => {
					this.closeImportModal();
					this.refreshEntitlementMatrixData(matrixResponse);
					toast('Success', 'The entitlement matrix has been successfully imported!', 'green');
				},
				error: (response: any) => {
					this.modalIsLoading = false;
					this.importFileErrors = flatten(Object.values(response));
				}
			})
		);
	}

	onFileSelected($event: Event) {

		const target = $event.target as HTMLInputElement;
		this.importFile = (target.files as FileList)[0];

		if (this.importFile) {
			this.fileName = this.importFile.name;
			this.isImportValid = true;
		}
		else {
			this.isImportValid = false;
			this.fileName = '';
			this.importFile = null;
		}
	}

	closeImportModal(): void {
		this.modalIsLoading = false;
		this.importMatrixModal.toggle();
		this.importFileErrors = [];
	}

	private refreshEntitlementMatrixData(matrix: EntitlementMatrixVersionHistoryDto): void {

		this.entitlementMatrixModel.groupPropertiesValidation = EntitlementMatrixGroupValidations.MainPage;

		this.modalIsLoading = false;

		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];

		this.headquarterService.clearHeadquartersExCache();

		this.subscriptions.push(
			this.headquarterService.getHeadquartersEx().subscribe({
				next: response => {

					this.assignmentService.clearCodesCache(this.entitlementMatrixModel.matrixVersionId!);
					this.allHeadquarterMatrixVersions = response;
					this.selectedHeadquarter = this.allHeadquarterMatrixVersions.find(hq => hq.id === matrix.headquarterId)!;

					this.headquarterDropdownValues = chain(this.allHeadquarterMatrixVersions)
						.map(e => new DropdownOption({
							value: e.id,
							name: e.name
						}))
						.value();

					this.entitlementMatrixModel.headquarterId = matrix.headquarterId;

					this.populateMatrixVersions(matrix);
				}
			})
		);
	}

	private addEntitlementMatrix(): void {

		const model = new CreateMatrixDto({
			headquarterId: this.selectedHeadquarter.id,
			startDate: this.entitlementMatrixModel.matrixVersionStartDate!,
			createBlank: this.entitlementMatrixModel.createBlankMatrixVersion
		});

		this.subscriptions.push(
			this.entitlementMatrixService.createEntitlementMatrix(model).subscribe({
				next: matrixResponse => {

					this.refreshEntitlementMatrixData(matrixResponse);
					this.addMatrixModal.toggle();
					toast('Success', `Matrix successfully added to ${this.selectedHeadquarter.name} HQ!`, 'green');
				},
				error: (response: any) => {

					this.modalIsLoading = false;

					const errors: string[] = flatten(Object.values(response));
					toast('Error', `Matrix could not be added! ${errors[0]}`, 'red');
				}
			})
		);
	}

	private editEntitlementMatrix(forceSave: boolean): void {
		const model = new UpdateMatrixDto({
			startDate: this.entitlementMatrixModel.matrixVersionStartDate!,
			endDate: this.entitlementMatrixModel.matrixVersionEndDate ? new Date(this.entitlementMatrixModel.matrixVersionEndDate.toDateString()) : this.entitlementMatrixModel.matrixVersionEndDate,
			forceSave: forceSave
		});

		this.subscriptions.push(
			this.entitlementMatrixService.updateEntitlementMatrix(this.entitlementMatrixModel.matrixVersionId!, model).subscribe({
				next: matrixResponse => {

					this.refreshEntitlementMatrixData(matrixResponse);
					this.addMatrixModal.toggle();

					toast('Success', `Matrix successfully modified for ${this.selectedHeadquarter.name} HQ!`, 'green');
				},
				error: (response: any) => {

					this.modalIsLoading = false;

					this.isForceSaveError = !!response['ForceSave'];
					this.errors = this.isForceSaveError ? response['ForceSave'] : [];

					if (!this.isForceSaveError) {
						const errors: string[] = flatten(Object.values(response));
						toast('Error', `Matrix could not be modified! ${errors[0]}`, 'red');
					}
				}
			})
		);

	}

	deleteMatrix(): void {
		this.modalIsLoading = true;

		this.subscriptions.push(
			this.entitlementMatrixService.deleteEntitlementMatrix(this.entitlementMatrixModel.matrixVersionId!).subscribe({
				next: () => {

					this.tableDataReady = false;
					this.entitlementMatrixTableData = [];

					this.headquarterService.clearHeadquartersExCache();

					this.subscriptions.push(
						this.headquarterService.getHeadquartersEx().subscribe({
							next: response => {

								this.allHeadquarterMatrixVersions = response;
								this.selectedHeadquarter = this.allHeadquarterMatrixVersions.find(hq => hq.id === this.entitlementMatrixModel.headquarterId)!;

								this.headquarterDropdownValues = chain(this.allHeadquarterMatrixVersions)
									.map(e => new DropdownOption({
										value: e.id,
										name: e.name
									}))
									.value();

								this.populateMatrixVersions();

								this.deleteMatrixModal.toggle();
								this.modalIsLoading = false;
							}
						})
					);

					toast('Success', 'Matrix successfully deleted!', 'green');
				},
				error: (response: any) => {

					this.deleteMatrixModal.toggle();
					this.modalIsLoading = false;

					const errors: string[] = flatten(Object.values(response.error.errors));
					toast('Error', `Matrix deleting encountered a problem! Error: ${errors[0]}`, 'red');
				}
			})
		);
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];

		this.initializeTable();
	}

	sortChange(col: ColumnSortation): void {
		this.pagedQueryModel.page = 1;
		this.pagedQueryModel.orderField = col.sortAsc !== null ? `${col.key} ${col.sortAsc ? 'asc' : 'desc'}` : '';

		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];

		this.initializeTable();
	}

	changeView(view: string): void {
		this.currentView = view === MainMatrixView.Codes ? MainMatrixView.Codes : MainMatrixView.Types;

		this.pagedQueryModel.page = 1;
		this.pagedQueryModel.orderField = view === MainMatrixView.Codes ? 'fullCode' : 'entitlementTypeName'

		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];

		this.initializeTable();
	}

	openAddMatrixElementModal(item: EntitlementCodeDto | any = {}): void {
		this.isEditModal = Object.keys(item).length !== 0;
		this.currentStep = EntitlementMatrixGroupValidations.EntitlementCodeInfo;

		this.entitlementMatrixModel.groupPropertiesValidation = EntitlementMatrixGroupValidations.EntitlementCodeInfo;
		this.selectedEntitlementCode = item as EntitlementCodeDto;

		switch (this.isEditModal) {
			case false:
				this.addEntitlementCodeElement();
				break;
			case true:
				this.editEntitlementCodeElement();
				break;
		}
	}

	private addEntitlementCodeElement(): void {

		this.entitlementMatrixModel.entitlementCodeId = null;
		this.entitlementMatrixModel.entitlementCodeGroup = null;
		this.entitlementMatrixModel.entitlementCodeDescription = null;

		this.entitlementCodesDropdownValues = [];

		this.assignmentService.clearUnassignedCodes(this.entitlementMatrixModel.matrixVersionId!);

		this.subscriptions.push(
			this.assignmentService.getUnassignedCodes(this.entitlementMatrixModel.matrixVersionId!).subscribe({
				next: response => {

					this.unassignedEntitlementCodes = response;

					this.entitlementCodesDropdownValues = chain(this.unassignedEntitlementCodes)
						.map(e => new DropdownOption({
							value: e.id,
							name: `${e.majorCode}.${e.minorCode}`
						}))
						.value();
				}
			})
		);

		this.getAllTypes();
	}

	private editEntitlementCodeElement(): void {

		this.entitlementMatrixModel.entitlementCodeId = this.selectedEntitlementCode.id;
		this.entitlementMatrixModel.entitlementCodeGroup = this.selectedEntitlementCode.group;
		this.entitlementMatrixModel.entitlementCodeDescription = this.selectedEntitlementCode.description;

		this.getAllTypes(this.entitlementMatrixModel.entitlementCodeId);
	}

	private getAllTypes(id: number | null = null): void {

		const requests: Observable<any>[] = [
			this.assignmentService.getAllTypes(this.entitlementMatrixModel.matrixVersionId!)
		];

		if (id) requests.push(this.assignmentService.getAssignmentsForCodeAndHeadquarter(this.entitlementMatrixModel.matrixVersionId!, this.selectedEntitlementCode.id));

		this.subscriptions.push(
			forkJoin(requests).subscribe({
				next: (responses: any) => {

					this.handleAllTypesResult(responses[0]);
					if (id) this.handleCodeHeadquarterAssignmentResult(responses[1]);
				}
			})
		);
	}

	private handleAllTypesResult(result: EntitlementTypeAssociationDto[]): void {
		this.rationedEntitlementTypeAssociationItems = [];
		this.nonRationedEntitlementTypeAssociationItems = [];

		result.forEach(element => {
			if (element.rationingTypeId === RationedType.Rationed) {
				const newItem = new AssociationItem({
					id: element.id,
					checked: false,
					entitlementTypeName: element.entitlementTypeName,
					amount: 0,
					unit: element.entitlementMeasurementUnitsAssociation ? element.entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitDescription : null
				});

				const validationItem: AssociationItem = createProxy(newItem, {
					set: () => this.validateAmount(newItem)
				});

				this.rationedEntitlementTypeAssociationItems.push(validationItem);

			} else {

				this.nonRationedEntitlementTypeAssociationItems.push(new AssociationItem({
					id: element.id,
					checked: false,
					entitlementTypeName: element.entitlementTypeName
				}));
			}
		});

		if (!this.rationedEntitlementTypeAssociationItems.length && !this.nonRationedEntitlementTypeAssociationItems.length) {
			toast('Warning', 'The selected Matrix Version does not have any Type Associations. Please assign one in order to continue with the matrix element addition!', 'orange');
			return;
		}

		this.addMatrixElementModal.toggle();
	}

	private handleCodeHeadquarterAssignmentResult(result: EntitlementAssignmentExtendedDto[]): void {
		result.forEach(item => {
			if (item.entitlementTypeAssociation.rationingTypeId === RationedType.Rationed) {
				this.rationedEntitlementTypeAssociationItems.forEach(rationedAssociation => {
					if (rationedAssociation.id === item.entitlementTypeAssociationId) {
						rationedAssociation.amount = item.unitCount;
						rationedAssociation.checked = true;
					}
				});
			} else {
				this.nonRationedEntitlementTypeAssociationItems.forEach(nonRationedAssociation => {
					if (nonRationedAssociation.id === item.entitlementTypeAssociationId) {
						nonRationedAssociation.checked = true;
					}
				});
			}
		});
	}

	closeMatrixElementModal(): void {
		this.entitlementMatrixModel.groupPropertiesValidation = EntitlementMatrixGroupValidations.MainPage;
		this.addMatrixElementModal.toggle();
	}

	back(): void {
		this.currentStep = EntitlementMatrixGroupValidations.EntitlementCodeInfo;
	}

	next(): void {
		this.currentStep = EntitlementMatrixGroupValidations.EntitlementTypeAssociation;
	}

	saveMatrixElement(): void {

		for (const item of this.rationedEntitlementTypeAssociationItems) {
			this.validateAmount(item);
			if (!this.validAmounts) break;
		}

		if (!this.validAmounts) {
			toast('Warning', 'Please select a valid amount for the checked entitlement types!', 'orange');
			return;
		}

		this.atleastOneEntitlementTypeCheck();

		if (!this.atleastOneChecked) {
			toast('Warning', 'Please check at least one entitlement type!', 'orange');
			return;
		}

		const assignments: EntitlementAssignmentModel[] = [];

		[...this.nonRationedEntitlementTypeAssociationItems, ...this.rationedEntitlementTypeAssociationItems]
			.forEach(item => {
				if (item.checked) {
					assignments.push(
						new EntitlementAssignmentModel({
							entitlementTypeAssociationId: item.id,
							unitCount: item.amount,
							entitlementCodeGroup: this.selectedUnassignedEntitlementCode.description !== this.entitlementMatrixModel.entitlementCodeDescription ||
								this.selectedUnassignedEntitlementCode.group !== this.entitlementMatrixModel.entitlementCodeGroup ? new EntitlementCodeGroupDto({
									description: this.entitlementMatrixModel.entitlementCodeDescription,
									group: this.entitlementMatrixModel.entitlementCodeGroup!
								}) : null
						}));
				}
			});

		this.modalIsLoading = true;

		if (!this.isEditModal) {
			this.subscriptions.push(
				this.assignmentService.createMatrixElement(this.entitlementMatrixModel.headquarterId!, this.entitlementMatrixModel.entitlementCodeId!, this.entitlementMatrixModel.matrixVersionId!, assignments)
					.subscribe({
						next: response => {
							this.modalIsLoading = false;
							this.addMatrixElementModal.toggle();

							toast('Success', 'Matrix Code successfully added!', 'green');

							this.refreshEntitlementMatrixData(response);
						},
						error: (response) => {
							this.modalIsLoading = false;

							const errors: string[] = flatten(Object.values(response));
							toast('Error', `Matrix Code add encountered a problem! ${errors[0]}`, 'red');
						}
					})
			);
		} else {
			this.subscriptions.push(
				this.assignmentService.updateMatrixElement(this.entitlementMatrixModel.headquarterId!, this.entitlementMatrixModel.entitlementCodeId!, this.entitlementMatrixModel.matrixVersionId!, assignments)
					.subscribe({
						next: response => {
							this.modalIsLoading = false;
							this.addMatrixElementModal.toggle();

							toast('Success', 'Matrix Code successfully modified!', 'green');

							this.refreshEntitlementMatrixData(response);
						},
						error: (response) => {
							this.modalIsLoading = false;

							const errors: string[] = flatten(Object.values(response));
							toast('Error', `Matrix Code modify encountered a problem! ${errors[0]}`, 'red');
						}
					})
			);
		}
	}

	private updateInitialMatrixVersionId(response: EntitlementMatrixVersionHistoryDto): void {

		if (response.initialMatrixVersionId) {
			const initialMatrixVersions = this.entitlementMatrixModel.matrixVersions.find(d => d.id === response.initialMatrixVersionId)!;

			initialMatrixVersions.id = response.id;
			this.entitlementMatrixModel.matrixVersionId = response.id;

			this.matrixVersionsDropdownValues = chain(this.entitlementMatrixModel.matrixVersions)
				.orderBy(e => e.startDate)
				.map(e => new DropdownOption({
					value: e.id,
					name: this.entitlementMatrixService.getHeadquarterMatrixVersionFormat(e)
				}))
				.value();
		}

		this.assignmentService.clearCodesCache(this.entitlementMatrixModel.matrixVersionId!);
		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];
		this.initializeTable();
	}

	atleastOneEntitlementTypeCheck(): void {
		const atleastOneRationedChecked = this.rationedEntitlementTypeAssociationItems.some(d => d.checked);
		const atleastOneNonRationedChecked = this.nonRationedEntitlementTypeAssociationItems.some(d => d.checked);

		this.atleastOneChecked = atleastOneRationedChecked || atleastOneNonRationedChecked;
	}

	onEntitlementCodeValueChange(): void {
		this.selectedUnassignedEntitlementCode = this.unassignedEntitlementCodes.find(d => d.id === this.entitlementMatrixModel.entitlementCodeId)!;

		this.entitlementMatrixModel.entitlementCodeGroup = this.selectedUnassignedEntitlementCode.group;
		this.entitlementMatrixModel.entitlementCodeDescription = this.selectedUnassignedEntitlementCode.description;
	}

	setDefaultAmount(item: AssociationItem): void {
		if (Object.keys(this.amountValidations[item.id]).length || !item.checked) item.amount = 0;

		this.atleastOneEntitlementTypeCheck();
	}

	onAmountValueChange(item: AssociationItem): void {
		item.checked = true;
		this.atleastOneEntitlementTypeCheck();
	}

	refreshTableData(): void {
		this.assignmentService.clearTypesCache(this.entitlementMatrixModel.matrixVersionId!);

		this.tableDataReady = false;
		this.entitlementMatrixTableData = [];
		this.initializeTable();
	}

	openDisableMatrixElementModal(item: EntitlementCodeDto | EntitlementTypeAssociationItem): void {

		switch (this.currentView) {
			case MainMatrixView.Codes:
				this.selectedEntitlementCode = item as EntitlementCodeDto;
				this.getActiveCardsData();
				break;
			case MainMatrixView.Types:
				this.selectedEntitlementType = item as EntitlementTypeAssociationItem;
				break;
		}

		this.matrixElementDisableModal.toggle();
	}

	private getActiveCardsData(): void {

		this.modalIsLoading = true;
		this.impactedActiveCardsBatches = [];
		this.assignmentService.clearActiveCardsCache(this.selectedHeadquarter.id, this.selectedEntitlementCode.id);

		this.subscriptions.push(
			this.assignmentService.getActiveCards(this.selectedHeadquarter.id, this.selectedEntitlementCode.id).subscribe({
				next: (response: string[]) => {
					this.impactedActiveCardsBatches = this.assignmentService.getActiveCardsBatches(response);
					this.modalIsLoading = false;
				},
				error: () => {
					this.modalIsLoading = false;
				}
			})
		);
	}

	disable(): void {
		this.modalIsLoading = true;

		switch (this.currentView) {
			case MainMatrixView.Codes:

				this.subscriptions.push(
					this.assignmentService.disableMatrixElement(this.selectedHeadquarter.id, this.selectedEntitlementCode.id, this.entitlementMatrixModel.matrixVersionId!)
						.subscribe({
							next: response => {
								this.modalIsLoading = false;
								this.matrixElementDisableModal.toggle();

								toast('Success', `Code ${this.selectedEntitlementCode.fullCode} successfully disabled!`, 'green');

								this.refreshEntitlementMatrixData(response);
							},
							error: (response: any) => {

								this.modalIsLoading = false;
								this.matrixElementDisableModal.toggle();

								const errors: string[] = flatten(Object.values(response));
								toast('Error', `Code ${this.selectedEntitlementCode.fullCode} disabling encountered a problem! ${errors[0]}`, 'red');
							}
						})
				);

				break;
			case MainMatrixView.Types:

				this.subscriptions.push(
					this.entitlementTypeAssociationService.revokeTypeAssociation(this.selectedHeadquarter.id, this.selectedEntitlementType.id, this.entitlementMatrixModel.matrixVersionId!)
						.subscribe({
							next: () => {
								this.modalIsLoading = false;
								this.matrixElementDisableModal.toggle();

								toast('Success', `Type ${this.selectedEntitlementType.entitlementTypeName} successfully disabled!`, 'green');

								this.assignmentService.clearTypesCache(this.entitlementMatrixModel.matrixVersionId!);

								this.tableDataReady = false;
								this.entitlementMatrixTableData = [];
								this.initializeTable();
							},
							error: (response: any) => {

								this.modalIsLoading = false;
								this.matrixElementDisableModal.toggle();

								const errors: string[] = flatten(Object.values(response));
								toast('Error', `Type ${this.selectedEntitlementType.entitlementTypeName} disabling encountered a problem! ${errors[0]}`, 'red');
							}
						})
				);

				break;
		}
	}

	isMatrixActive(matrix: MatrixVersionHistoryDto): boolean {

		if (Object.keys(matrix).length === 0) return false;

		const today = new Date(new Date().toDateString());
		const formattedStartDate = new Date(new Date(matrix.startDate).toDateString());
		const formattedEndDate = new Date(new Date(matrix.endDate).toDateString());

		this.expiresToday = today.toDateString() === formattedEndDate.toDateString();

		if (formattedStartDate <= today && today <= formattedEndDate) {
			return true;
		}

		return false;
	}
}


import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@nstep-common/core';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import {
	EntitlementIssuanceTypeDto,
	EntitlementTypeAssociationDto,
	EntitlementTypeAssociationItem,
	EntitlementTypeDto,
	EntitlementUnitModel,
	IssuenceType,
	TypeAssociationModel,
	TypeAssociationModelValidator,
	UnitAssociationDto
} from '@nstep-internal/pages';
import { AssignmentService, EntitlementMatrixService, EntitlementTypeAssociationService, EntitlementTypeService, EntitlementUnitService } from '@nstep-internal/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain } from 'lodash';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-type-association-modal',
	templateUrl: './type-association-modal.component.html',
})
export class TypeAssociationModalComponent extends BaseComponent {

	@ViewChild('addTypeAssociationModal') addTypeAssociationModal!: ModalComponent;

	@Input() headquarterId: number | null = null;
	@Input() matrixVersionId: number | null = null;
	selectedEntitlementType: EntitlementTypeAssociationItem = {} as EntitlementTypeAssociationItem;
	@Input() tableData: any[] = [];
	@Input() existingEntitlementTypeIds: number[] = [];
	@Output() refreshTableData = new EventEmitter();
	@Output() addColumn = new EventEmitter<EntitlementTypeAssociationItem>();

	isLoading: boolean = false;
	isEdit: boolean = false;
	fromAdministerView: boolean = false;

	calendarSettings = {
		type: 'date',
		minDate: new Date(new Date().toDateString())
	};

	typeAssociationsDropdownValues: DropdownOption[] = [];
	issuanceTypesDropdownValues: DropdownOption[] = [];
	periodCyclesDropdownValues: DropdownOption[] = [];
	measurementUnitsDropdownValues: DropdownOption[] = [];

	enabledEntitlementTypes: EntitlementTypeDto[] = [];
	selectedEnabledType: EntitlementTypeDto = {} as EntitlementTypeDto;

	validation: ValidationErrors<TypeAssociationModel> = {};
	isValid: boolean = false;

	typeAssociationModel: TypeAssociationModel = createProxy(new TypeAssociationModel(), {
		set: () => this.validate(this.typeAssociationModel)
	});

	validate(value: TypeAssociationModel): void {
		const validator = new TypeAssociationModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private assignmentService: AssignmentService,
		private entitlementTypeService: EntitlementTypeService,
		private entitlementUnitService: EntitlementUnitService,
		public entitlementMatrixService: EntitlementMatrixService,
		private entitlementTypeAssociationService: EntitlementTypeAssociationService) {
		super();
	}

	openAddTypeAssociationModal(item: EntitlementTypeAssociationItem | any = {}, fromAdministerView = false): void {

		this.fromAdministerView = fromAdministerView;

		this.isEdit = Object.keys(item).length !== 0;

		this.selectedEntitlementType = item as EntitlementTypeAssociationItem;
		this.selectedEnabledType = {} as EntitlementTypeDto;

		switch (this.isEdit) {
			case false:
				this.addTypeAssociation();
				break;
			case true:
				this.editTypeAssociation();
				break;
		}
	}

	private addTypeAssociation(): void {

		this.typeAssociationModel.fromAdministerView = this.fromAdministerView;
		this.typeAssociationModel.isEdit = false;
		this.typeAssociationModel.entitlementTypeId = null;
		this.typeAssociationModel.issuanceTypeId = null;
		this.typeAssociationModel.isBuyable = false;
		this.typeAssociationModel.isNotProRated = false;
		this.typeAssociationModel.periodCycle = null;
		this.typeAssociationModel.periodsUntilExpiry = null;
		this.typeAssociationModel.periodsIssuedUpfront = null;
		this.typeAssociationModel.typeAssociationStartDate = null;
		this.typeAssociationModel.typeAssociationEndDate = null;
		this.typeAssociationModel.measurementUnitId = null;
		this.typeAssociationModel.unitDescription = null;

		this.periodCyclesDropdownValues = chain(this.assignmentService.periodCycles)
			.map(r => new DropdownOption({
				name: r.Text,
				value: r.Value
			}))
			.value();

		const requests = [
			this.entitlementUnitService.getEnabledEntitlementUnits(),
			this.entitlementTypeAssociationService.getIssuanceTypes(),
			this.entitlementTypeService.getEnabledEntitlementTypes()
		];

		this.subscriptions.push(
			forkJoin(requests).subscribe({
				next: (results: any) => {
					this.handleEnabledEntitlementUnitsResult(results[0]);
					this.handleIssuanceTypesResult(results[1]);
					this.handleEnabledEntitlementTypesResult(results[2]);
				}
			})
		);
	}

	private editTypeAssociation(): void {

		this.typeAssociationModel.fromAdministerView = this.fromAdministerView;
		this.typeAssociationModel.isEdit = true;
		this.typeAssociationModel.entitlementTypeId = this.selectedEntitlementType.entitlementTypeId;
		this.typeAssociationModel.rationingTypeId = this.selectedEntitlementType.rationingTypeId;
		this.typeAssociationModel.issuanceTypeId = this.selectedEntitlementType.entitlementIssuanceTypeId;
		this.typeAssociationModel.isBuyable = this.selectedEntitlementType.isBuyable;
		this.typeAssociationModel.isNotProRated = this.selectedEntitlementType.isNotProRated!;
		this.typeAssociationModel.periodCycle = this.selectedEntitlementType.periodCycle;
		this.typeAssociationModel.periodsUntilExpiry = this.selectedEntitlementType.periodsValid;
		this.typeAssociationModel.periodsIssuedUpfront = this.selectedEntitlementType.periodsIssuedInAdvance;
		this.typeAssociationModel.typeAssociationStartDate = this.selectedEntitlementType.startDate ? new Date(this.selectedEntitlementType.startDate) : null;
		this.typeAssociationModel.typeAssociationEndDate = this.selectedEntitlementType.endDate ? new Date(this.selectedEntitlementType.endDate) : null;
		this.typeAssociationModel.measurementUnitId = this.selectedEntitlementType.measurementUnitId;
		this.typeAssociationModel.unitDescription = this.selectedEntitlementType.entitlementMeasurementUnitDescription;

		this.periodCyclesDropdownValues = chain(this.assignmentService.periodCycles)
			.map(r => new DropdownOption({
				name: r.Text,
				value: r.Value
			}))
			.value();

		const requests = [
			this.entitlementUnitService.getEnabledEntitlementUnits(),
			this.entitlementTypeAssociationService.getIssuanceTypes()
		];

		this.subscriptions.push(
			forkJoin(requests).subscribe({
				next: (results: any) => {
					this.handleEnabledEntitlementUnitsResult(results[0]);
					this.handleIssuanceTypesResult(results[1], true);
				}
			})
		);
	}

	private handleEnabledEntitlementUnitsResult(result: EntitlementUnitModel[]): void {

		this.measurementUnitsDropdownValues = chain(result)
			.map(r => new DropdownOption({
				name: r.name,
				value: r.id
			}))
			.orderBy(r => r.name)
			.value();
	}

	private handleIssuanceTypesResult(result: EntitlementIssuanceTypeDto[], toggleModal = false): void {

		const issuanceTypes: EntitlementIssuanceTypeDto[] = [
			{ id: 3, name: 'Once' }
		];
		issuanceTypes.push(...result);
		issuanceTypes.push({ id: 4, name: 'Adjusted by Operator' });

		this.issuanceTypesDropdownValues = chain(issuanceTypes)
			.map(r => new DropdownOption({
				name: r.name,
				value: r.id
			}))
			.value();

		if (this.typeAssociationModel.periodCycle === -1) {
			this.typeAssociationModel.issuanceTypeId = IssuenceType.Once;
		}

		if (this.typeAssociationModel.periodCycle === 0) {
			this.typeAssociationModel.issuanceTypeId = IssuenceType.AdjustedByOperator;
		}

		if (this.typeAssociationModel.issuanceTypeId === IssuenceType.Once || this.typeAssociationModel.issuanceTypeId === IssuenceType.AdjustedByOperator) {
			this.typeAssociationModel.periodCycle = null;
		}

		if (toggleModal) this.addTypeAssociationModal.toggle();
	}

	private handleEnabledEntitlementTypesResult(result: EntitlementTypeDto[]): void {

		this.enabledEntitlementTypes = result;

		this.typeAssociationsDropdownValues = chain(result)
			.filter(r => !this.existingEntitlementTypeIds.includes(r.id))
			.map(r => new DropdownOption({
				name: r.name,
				value: r.id
			}))
			.orderBy(r => r.name)
			.value();

		if (this.typeAssociationsDropdownValues.length) {
			this.addTypeAssociationModal.toggle();
		} else {
			toast('Warning', 'There are no unassigned entitlement types available. Please go to Entitlement Types page and create a new entitlement type.', 'orange');
		}
	}

	onTypeAssociationValueChange(): void {
		this.selectedEnabledType = this.enabledEntitlementTypes.find(e => e.id === this.typeAssociationModel.entitlementTypeId)!;
		this.typeAssociationModel.rationingTypeId = this.selectedEnabledType.rationingTypeId;
	}

	onIssuanceTypeValueChange(): void {

		if (!this.isEdit) {
			switch (this.typeAssociationModel.issuanceTypeId) {
				case IssuenceType.Once:
					this.typeAssociationModel.isNotProRated = false;
					this.typeAssociationModel.periodCycle = null;
					this.typeAssociationModel.periodsUntilExpiry = null;
					this.typeAssociationModel.periodsIssuedUpfront = null;
					this.typeAssociationModel.typeAssociationStartDate = null;
					this.typeAssociationModel.typeAssociationEndDate = null;
					break;
				case IssuenceType.Periodic:
					this.typeAssociationModel.isNotProRated = false;
					break;

				case IssuenceType.PeriodicProRated:
					this.typeAssociationModel.periodsUntilExpiry = null;
					this.typeAssociationModel.periodsIssuedUpfront = null;
					break;

				case IssuenceType.AdjustedByOperator:
					this.typeAssociationModel.isNotProRated = false;
					this.typeAssociationModel.periodCycle = null;
					this.typeAssociationModel.periodsUntilExpiry = null;
					this.typeAssociationModel.periodsIssuedUpfront = null;
					break;
			}

			this.typeAssociationModel.periodCycle = null;
		}
	}

	typeAssociationMainSave(): void {

		this.isLoading = true;

		const model = this.createTypeAssociationModel();

		if (!this.isEdit) {
			this.subscriptions.push(
				this.entitlementTypeAssociationService.createTypeAssociation(this.headquarterId!, this.matrixVersionId, model).subscribe({
					next: () => {
						this.handleTypeAssociatonSuccessResult();

						toast('Success', `Entitlement Type ${this.selectedEnabledType.name} successfully added!`, 'green');
					},
					error: () => {
						this.isLoading = false;

						toast('Error', `Entitlement Type ${this.selectedEnabledType.name} addition encountered a problem!`, 'red');
					}
				})
			);
		} else {
			this.updateTypeAssociation(model);
		}
	}

	private createTypeAssociationModel(): EntitlementTypeAssociationDto {
		const model = new EntitlementTypeAssociationDto({
			id: this.isEdit ? this.selectedEntitlementType.id : 0,
			entitlementTypeId: this.typeAssociationModel.entitlementTypeId!
		});

		if (this.typeAssociationModel.rationingTypeId === 1) {

			model.entitlementIssuanceTypeId = this.typeAssociationModel.issuanceTypeId;
			model.isBuyable = this.typeAssociationModel.isBuyable;
			model.entitlementMeasurementUnitsAssociation = new UnitAssociationDto({
				entitlementMeasurementUnitId: this.typeAssociationModel.measurementUnitId!,
				entitlementMeasurementUnitDescription: this.typeAssociationModel.unitDescription!
			});

			switch (this.typeAssociationModel.issuanceTypeId) {
				case IssuenceType.Once:
					model.entitlementIssuanceTypeId = 1;
					model.periodCycle = -1;
					break;

				case IssuenceType.Periodic:
					model.periodCycle = this.typeAssociationModel.periodCycle;
					model.periodsValid = this.typeAssociationModel.periodsUntilExpiry;
					model.periodsIssuedInAdvance = this.typeAssociationModel.periodsIssuedUpfront;
					model.startDate = this.typeAssociationModel.typeAssociationStartDate ? new Date(this.typeAssociationModel.typeAssociationStartDate.toDateString()) : null;
					model.endDate = this.typeAssociationModel.typeAssociationEndDate ? new Date(this.typeAssociationModel.typeAssociationEndDate.toDateString()) : null;
					break;

				case IssuenceType.PeriodicProRated:
					model.isNotProRated = this.typeAssociationModel.isNotProRated;
					model.periodCycle = this.typeAssociationModel.periodCycle;
					model.startDate = this.typeAssociationModel.typeAssociationStartDate ? new Date(this.typeAssociationModel.typeAssociationStartDate.toDateString()) : null;
					model.endDate = this.typeAssociationModel.typeAssociationEndDate ? new Date(this.typeAssociationModel.typeAssociationEndDate.toDateString()) : null;
					break;

				case IssuenceType.AdjustedByOperator:
					model.entitlementIssuanceTypeId = 1;
					model.periodCycle = 0;
					model.periodsValid = this.typeAssociationModel.periodsUntilExpiry;
					model.startDate = this.typeAssociationModel.typeAssociationStartDate ? new Date(this.typeAssociationModel.typeAssociationStartDate.toDateString()) : null;
					model.endDate = this.typeAssociationModel.typeAssociationEndDate ? new Date(this.typeAssociationModel.typeAssociationEndDate.toDateString()) : null;
					break;
			}
		}

		return model;
	}

	private updateTypeAssociation(model: EntitlementTypeAssociationDto): void {
		this.subscriptions.push(
			this.entitlementTypeAssociationService.updateTypeAssociation(this.headquarterId!, this.matrixVersionId, model).subscribe({
				next: () => {
					this.handleTypeAssociatonSuccessResult();

					toast('Success', `Entitlement Type ${this.selectedEntitlementType.entitlementTypeName} successfully modified!`, 'green');
				},
				error: () => {
					this.isLoading = false;

					toast('Error', `Entitlement Type ${this.selectedEntitlementType.entitlementTypeName} modifying encountered a problem!`, 'red');
				}
			})
		);
	}

	private handleTypeAssociatonSuccessResult(): void {
		this.addTypeAssociationModal.toggle();
		this.isLoading = false;

		this.refreshTableData.emit();
	}

	typeAssociationAdministerSave(): void {
		this.isLoading = true;

		if (!this.isEdit) {
			this.addColumn.emit(new EntitlementTypeAssociationItem({
				entitlementTypeId: this.typeAssociationModel.entitlementTypeId!,
				entitlementTypeName: this.selectedEnabledType.name,
				rationingTypeId: this.typeAssociationModel.rationingTypeId!,
				measurementUnitId: this.typeAssociationModel.measurementUnitId,
				entitlementMeasurementUnitDescription: this.typeAssociationModel.unitDescription,
				entitlementMeasurementUnitName: this.measurementUnitsDropdownValues.find(d => d.value === this.typeAssociationModel.measurementUnitId)?.name
			}));

		} else {
			const model = this.createTypeAssociationModel();
			this.updateTypeAssociation(model);
		}
	}

	toggle = () => this.addTypeAssociationModal.toggle();
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@nstep-common/core';
import { valueHasChanged } from '@nstep-common/utils';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html'
})
export class SearchComponent extends BaseComponent implements AfterViewInit, OnChanges, OnDestroy {
	//@Input() search: string = '';
	search: string = '';

	@Input() result: any = null;
	@Output() resultChange = new EventEmitter<any>();

	@Input() onSearch!: (term: string, callback: (results: {
		obj: any;
		description: string;
		title: string;
	}[]) => void) => void;

	nrDisplayedResults = 50;
	totalResults = '';

	constructor(private elementRef: ElementRef) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		//if (valueHasChanged(changes['search']) && !this.search) {
		//	$(this.elementRef.nativeElement)
		//		.find('.ui.search')
		//		.search('set value', '');
		//}

		if (valueHasChanged(changes['result']) && !this.result) {
			$(this.elementRef.nativeElement)
				.find('.ui.search')
				.search('set value', '');
		}
	}

	ngAfterViewInit(): void {
		const employeesSearch = $(this.elementRef.nativeElement)
			.find('.ui.search')
			.search({
				minCharacters: 3,
				searchOnFocus: false,
				searchDelay: 300,
				cache: false,
				maxResults: this.nrDisplayedResults,
				onSelect: (result: any) => {
					this.result = result.obj;
					this.resultChange.emit(result.obj);

					return true;
				},
				onResults: (response: any) => {
					this.totalResults = response.count > this.nrDisplayedResults
						? this.totalResults = 'Showing ' + this.nrDisplayedResults + ' out of ' + response.count + ' result(s).'
						: this.totalResults = 'Found ' + response.count + ' result(s).';
				},
				onResultsClose: () => {
					this.totalResults = '';
				},
				apiSettings: {
					responseAsync: (_: any, callback: any) => {
						const key = employeesSearch.search('get value');

						if (!key) {
							callback({
								success: false,
								results: [],
								count: 0
							});

							return;
						}

						this.onSearch(key, (results) => {
							callback({
								succcess: true,
								results,
								count: results.length
							});
						});
					}
				}
			});
	}

	ngOnDestroy(): void {
		$(this.elementRef.nativeElement)
			.find('.ui.search')
			.search('destroy');
	}
}
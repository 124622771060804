import { Component, OnInit } from '@angular/core';
import { BaseComponent, ColumnSortation, PagedQueryParameter, PaginationModel, TableColumn } from '@nstep-common/core';
import { SystemService } from '@nstep-internal/shared';

@Component({
	selector: 'app-import-history',
	templateUrl: './import-history.component.html'
})
export class ImportHistoryComponent extends BaseComponent implements OnInit {

	tableData: any[] = [];
	tableDataReady = true;

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: 'fileName',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	tableColumns: TableColumn[] = [
		{ name: 'Import File Name', key: 'fileName', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Date', key: 'date', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Type', key: 'isManual', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Size (kB)', key: 'size', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Status', key: 'status', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Duration (ms)', key: 'duration', isCellCentered: true, isHeaderCentered: true }
	];

	constructor(private systemConfigurationService: SystemService) {
		super();
	}

	ngOnInit(): void {
		this.initializeTableData();
	}

	initializeTableData(): void {
		this.tableDataReady = false;
		this.tableData = [];

		this.subscriptions.push(
			this.systemConfigurationService.getImportHistory(this.pagedQueryModel).subscribe({
				next: response => {

					this.pagination = response.page;
					this.tableData = response.results;

					this.tableDataReady = true;
				},
				error: () => {
					this.tableDataReady = true;
				}
			})
		);
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.initializeTableData();
	}

	sortChange(col: ColumnSortation): void {
		this.pagedQueryModel.page = 1;
		this.pagedQueryModel.orderField = col.sortAsc !== null ? `${col.key} ${col.sortAsc ? 'asc' : 'desc'}` : '';

		this.tableDataReady = false;
		this.tableData = [];

		this.initializeTableData();
	}
}

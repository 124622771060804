import { JsonProperty } from '@nstep-common/utils';
import { EntitlementAssignmentModel, EntitlementCodeDto, EntitlementTypeAssociationItem, MatrixAdminAssignmentDto } from '@nstep-internal/pages';

export class MatrixAdministrationCodeDto {
	@JsonProperty({ type: EntitlementCodeDto })
	entitlementCode!: EntitlementCodeDto;

	@JsonProperty({ type: MatrixAdminAssignmentDto, array: true })
	assignments!: MatrixAdminAssignmentDto[];
}

export class MatrixAdministrationGroup {
	key!: string;
	activeMatrixVersion: MatrixAdministrationRow | null = null;
	previousMatrixVersion: MatrixAdministrationRow | null = null;

	constructor(data: Partial<MatrixAdministrationGroup> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class MatrixAdministrationRow {
	expanded: boolean = true;
	entitlementCode!: EntitlementCodeDto;
	cells: MatrixAdministrationCell[] = [];
	isPreviousMatrixVersionRow: boolean = false;
	canBeAddedToActiveMatrix: boolean = false;
	isNew: boolean = false;

	constructor(data: Partial<MatrixAdministrationRow> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class MatrixAdministrationCell {
	id!: number;
	validationId!: string;
	rationingTypeId!: number;
	entitlementTypeId!: number;
	entitlementTypeName!: string;
	checked: boolean = false;
	amount: number | null = 0;

	//flag to check and display differences between previous version and active version
	isDifferent: boolean = false;

	isNew: boolean = false;
	isDeleted: boolean = false;
	initialValues: InitialValues | null = null;

	//flag to check if active version matrix cell has been modified
	isModified: boolean = false;
	asPlaceholder: boolean = false;

	constructor(data: Partial<MatrixAdministrationCell> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class InitialValues {
	checked: boolean = false;
	amount: number | null = 0;

	constructor(data: Partial<InitialValues> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class MatrixDeltaModel {
	createdAssigns: MatrixAssignModel[] = [];
	disabledAssigns: MatrixAssignModel[] = [];
	modifiedAssigns: MatrixAssignModel[] = [];

	constructor(data: Partial<MatrixDeltaModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class MatrixAssignModel {
	codeId!: number;
	assignments: EntitlementAssignmentModel[] = [];

	constructor(data: Partial<MatrixAssignModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class MatrixAdministrationModel {
	entitlementCodeId: number | null = null;
	entitlementCodeGroup: string | null = null;
	entitlementCodeDescription: string | null = null;

	constructor(data: Partial<MatrixAdministrationModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ContextMenuItemType {
	type: ContextMenuType | null = null;
	item: any | null = null;

	constructor(data: Partial<ContextMenuItemType> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum ContextMenuType {
	Column = 'Column',
	Row = 'Row'
}

export class AssociationTypeCopyItem {
	entitlementFullCode: string = '';
	cell: MatrixAdministrationCell | null = null;

	constructor(data: Partial<AssociationTypeCopyItem> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class CodeCopyItem {
	entitlementFullCode: string = '';
	cells: MatrixAdministrationCell[] = [];
	constructor(data: Partial<CodeCopyItem> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum ConfirmationType {
	CancelChanges = 'CancelChanges',
	DeleteColumn = 'DeleteColumn',
	DeleteRow = 'DeleteRow'
}

export class CompareColumnsModel {
	activeMatrix: { [id: string]: EntitlementTypeAssociationItem } = {};
	previousMatrix: { [id: string]: EntitlementTypeAssociationItem } = {};
}
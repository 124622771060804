<h3 class="ui header">
	<i class="file download icon"></i>
	<div class="content">
		Export
		<div class="sub header">System</div>
	</div>
</h3>
<br />

<div app-loading [enabled]="loading" class="ui form">
	<div class="field">
		<h4>Export data manually</h4>
		<div class="fields">
			<div class="field">
				<button class="ui fluid primary button" (click)="exportMatrixModal.toggle();">Export Matrix</button>
			</div>
		</div>
	</div>

	<div class="ui divider"></div>

	<div class="field">
		<h4>Export Transactions</h4>
		<div class="ui stackable grid">
			<div class="six wide column">
				<div class="field">
					<label>Headquarters*</label>
					<div class="ui segment" app-loading [enabled]="!headquartersDataReady" [height]="'40px'" style="margin: 0; overflow: auto; max-height: 200px;">
						<div class="field">
							<div class="ui checkbox" style="margin-top: 0px">
								<input type="checkbox" id="all-headquarters" [(ngModel)]="allHeadquarters" (change)="onAllHeadquartersValueChange(allHeadquarters)">
								<label for="all-headquarters">All</label>
							</div>
						</div>

						<div class="ui divider" style="margin-top: 5px; margin-bottom: 5px;"></div>

						<div class="field" *ngFor="let hq of headquarters" style="margin-bottom: 0px;">
							<div class="ui checkbox">
								<input type="checkbox" id="headquarter-{{hq.id}}" [disabled]="hq.disabled" [(ngModel)]="hq.isChecked" (change)="onHeadquarterValueChange(hq)">
								<label for="headquarter-{{hq.id}}">{{hq.name}}</label>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="six wide column">
				<div class="field">
					<label>POS Location*</label>
					<div class="ui segment" [class.disabled]="!posLocationsGroups.length" style="margin: 0; overflow: auto; max-height: 200px;">
						<div class="field">
							<div class="ui checkbox" style="margin-top: 0px">
								<input type="checkbox" id="all-pos-locations" [(ngModel)]="allPosLocations" (change)="onAllPosLocationsValueChange(allPosLocations)">
								<label for="all-pos-locations">All</label>
							</div>
						</div>

						<div class="ui divider" style="margin-top: 5px; margin-bottom: 5px;"></div>

						<div class="field" *ngFor="let group of posLocationsGroups; let isLast=last" style="margin-bottom: 5px;">
							<div class="ui checkbox">
								<input id="pos-location-group-{{group.headquarterId}}" type="checkbox" [(ngModel)]="group.isChecked" (change)="onPosLocationGroupValueChange(group)">
								<label for="pos-location-group-{{group.headquarterId}}"><strong>{{group.headquarterName}}</strong></label>
							</div>

							<div *ngFor="let posLocation of group.posLocations">
								<div class="ui checkbox" style="margin-left: 25px;">
									<input id="pos-location-{{group.headquarterId}}-{{posLocation.id}}" type="checkbox" [(ngModel)]="posLocation.isChecked" (change)="onPosLocationValueChange(group)">
									<label for="pos-location-{{group.headquarterId}}-{{posLocation.id}}">{{posLocation.name}}</label>
								</div>
							</div>

							<div class="ui divider" style="margin-top: 3px; margin-bottom: 0px;" *ngIf="!isLast"></div>
						</div>
					</div>
				</div>
			</div>

			<div class="four wide column">
				<div class="field" app-field [error]="exportModelValidation | path: 'from'">
					<label>From</label>
					<sm-calendar class="ui calendar"
						[settings]="calendarSettings"
						[(value)]="exportModel.from"
						[maxValue]="exportModel.to">
						<div class="ui fluid input left icon">
							<i class="calendar icon"></i>
							<input type="text" placeholder="Date" />
						</div>
					</sm-calendar>
				</div>

				<div class="field" app-field [error]="exportModelValidation | path: 'to'">
					<label>To</label>
					<sm-calendar class="ui calendar"
						[settings]="calendarSettings"
						[(value)]="exportModel.to"
						[minValue]="exportModel.from">
						<div class="ui fluid input left icon">
							<i class="calendar icon"></i>
							<input type="text" placeholder="Date" />
						</div>
					</sm-calendar>
				</div>

				<button class="ui fluid primary button"
					[disabled]="!validExportModel"
					(click)="exportTransactions()">
					Export Transactions
				</button>
			</div>
		</div>

	</div>

	<div class="ui divider" style="margin-top: 20px;"></div>

	<div class="field">
		<app-table-view
			[itemTemplate]="exportTransactionsItemTemplate"
			[data]="tableData"
			[dataReady]="tableDataReady"
			[columns]="tableColumns"
			[noSearch]="true"
			[useServerPagination]="true"
			[pageSize]="pagination.pageSize"
			[currentPage]="pagination.currentPage"
			[totalPages]="pagination.totalPages"
			(pageChangeEvent)="pageChange($event)"
			(sortChangeEvent)="sortChange($event)">
		</app-table-view>
	</div>
</div>

<ng-template #exportTransactionsItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>{{item.fileName | dash}}</td>
		<td>{{item.date | date: 'dd/MM/yyyy - h:mm a' | dash}}</td>
		<td>{{item.size | dash}}</td>
		<td>{{item.status ? 'Completed' : 'Failed'}}</td>
		<td>{{item.duration | dash}}</td>
	</tr>
</ng-template>

<sm-modal [isLoading]="modalIsLoading" #exportMatrixModal (closeEvent)="exportMatrixModal.toggle();" [isTiny]="true">
	<ng-container header>Export Data</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="exportMatrixValidation | path: 'headquarterId'">
				<label>Headquarter</label>
				<sm-dropdown class="ui fluid selection dropdown label"
					[(value)]="exportMatrixModel.headquarterId"
					[options]="headquartersDropdownValues"
					(valueChange)="onHeadquartersValueChange()">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>

			<div class="field" app-field [error]="exportMatrixValidation | path: 'matrixVersionId'">
				<label>Version</label>
				<sm-dropdown class="ui fluid selection dropdown label"
					[(value)]="exportMatrixModel.matrixVersionId"
					[options]="matrixVersionsDropdownValues">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>
		</div>

		<app-error-message [errors]="errors"></app-error-message>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="exportMatrixModal.toggle();">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!validExportMatrix"
			(click)="save()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>
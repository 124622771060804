import { JsonProperty } from '@nstep-common/utils';
import { MatrixVersionHistoryDto } from '@nstep-internal/pages';

export enum EntitlementMatrixGroupValidations {
	MainPage = 1,
	AddMatrix = 1.1,
	EntitlementCodeInfo = 2,
	EntitlementTypeAssociation = 3
}

export enum MainMatrixView {
	Codes = 'Codes',
	Types = 'Types',
}

export enum IssuenceType {
	Once = 3,
	Periodic = 1,
	PeriodicProRated = 2,
	AdjustedByOperator = 4
}

export class EntitlementMatrixModel {
	headquarterId: number | null = null;
	matrixVersionId: number | null = null;

	matrixVersionStartDate: Date | null = null;
	matrixVersionEndDate: Date | null = null;
	createBlankMatrixVersion: boolean = false;
	endOfTime: boolean = false;

	entitlementCodeId: number | null = null;
	entitlementCodeGroup: string | null = null;
	entitlementCodeDescription: string | null = null;

	matrixVersions!: MatrixVersionHistoryDto[];

	groupPropertiesValidation!: EntitlementMatrixGroupValidations;

	constructor(data: Partial<EntitlementMatrixModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementCodeDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	majorCode!: number;

	@JsonProperty({ type: Number })
	minorCode!: number;

	@JsonProperty({ type: String })
	fullCode!: string;

	@JsonProperty({ type: String, nullable: true })
	group: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	description: string | null = null;

	@JsonProperty({ type: Boolean })
	isDependent!: boolean;

	constructor(data: Partial<EntitlementCodeDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class UnitAssociationDto {
	@JsonProperty({ type: Number })
	entitlementUnitAssociationId!: number;

	@JsonProperty({ type: Number })
	entitlementMeasurementUnitId!: number;

	@JsonProperty({ type: String })
	entitlementMeasurementUnitName!: string;

	@JsonProperty({ type: String })
	entitlementMeasurementUnitDescription!: string;

	@JsonProperty({ type: Number })
	measurementUnitMultiplier!: number;

	constructor(data: Partial<UnitAssociationDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementTypeAssociationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	entitlementTypeId!: number;

	@JsonProperty({ type: String })
	entitlementTypeName!: string;

	@JsonProperty({ type: Number })
	rationingTypeId!: number;

	@JsonProperty({ type: Boolean })
	entitlementTypeDisabled!: boolean;

	@JsonProperty({ type: Number })
	entitlementTypeMinBuyAmount!: number;

	@JsonProperty({ type: Number, nullable: true })
	entitlementIssuanceTypeId!: number | null;

	@JsonProperty({ type: Number, nullable: true })
	periodCycle!: number | null;

	@JsonProperty({ type: Number, nullable: true })
	periodsValid!: number | null;

	@JsonProperty({ type: Number, nullable: true })
	periodsIssuedInAdvance!: number | null;

	@JsonProperty({ type: Date, nullable: true })
	startDate!: Date | null;

	@JsonProperty({ type: Date, nullable: true })
	endDate!: Date | null;

	@JsonProperty({ type: Boolean })
	isBuyable!: boolean;

	@JsonProperty({ type: Boolean, nullable: true })
	isNotProRated!: boolean | null;

	@JsonProperty({ type: Number, nullable: true })
	entitlementMeasurementUnitAssociationId!: number | null;

	@JsonProperty({ type: Number })
	matrixVersionId!: number;

	@JsonProperty({ type: UnitAssociationDto, nullable: true })
	entitlementMeasurementUnitsAssociation!: UnitAssociationDto | null;

	entitlementAssignments!: any[];

	constructor(data: Partial<EntitlementTypeAssociationDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementTypeAssociationItem {
	id!: number;
	matrixVersionId!: number;
	entitlementTypeId!: number;
	entitlementTypeName!: string;
	rationingTypeId!: number;
	measurementUnitId!: number | null;
	entitlementMeasurementUnitAssociationId!: number | null;
	entitlementMeasurementUnitDescription!: string | null;
	entitlementMeasurementUnitName!: string | null;
	entitlementIssuanceTypeId!: number | null;
	periodCycle!: number | null;
	periodsIssuedInAdvance!: number | null;
	periodsValid!: number | null;
	startDate: Date | null = null;
	endDate: Date | null = null;
	period: any;
	isBuyable!: boolean;
	isNotProRated!: boolean | null;
	deleted: boolean = false;

	constructor(data: Partial<EntitlementTypeAssociationItem> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class AssociationItem {
	id!: number;
	checked!: boolean;
	entitlementTypeName!: string;
	amount: number | null = null;
	unit: string | null = null;

	constructor(data: Partial<AssociationItem> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementMatrixVersionHistoryDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number, nullable: true })
	initialMatrixVersionId: number | null = null;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date, nullable: true })
	endDate: Date | null = null;

	@JsonProperty({ type: Date })
	createdDate!: Date;

	@JsonProperty({ type: Number, nullable: true })
	majorVersion: number | null = null;

	@JsonProperty({ type: Number, nullable: true })
	minorVersion: number | null = null;
}

export class EntitlementCodeGroupDto {
	group: string | null = null;
	description: string | null = null;

	constructor(data: Partial<EntitlementCodeGroupDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementAssignmentModel {
	entitlementTypeAssociationId!: number;
	entitlementCodeGroup: EntitlementCodeGroupDto | null = null;
	unitCount: number | null = null;

	constructor(data: Partial<EntitlementAssignmentModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementAssignmentDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	entitlementCodeId!: number;

	@JsonProperty({ type: Number, nullable: true })
	entitlementCodeGroupId: number | null = null;

	@JsonProperty({ type: Number, nullable: true })
	unitCount: number | null = null;
}

export class EntitlementAssignmentExtendedDto extends EntitlementAssignmentDto {
	@JsonProperty({ type: Number })
	entitlementTypeAssociationId!: number;

	@JsonProperty({ type: Number })
	matrixVersionId!: number;

	@JsonProperty({ type: EntitlementTypeAssociationDto })
	entitlementTypeAssociation!: EntitlementTypeAssociationDto;
}

export class MatrixAdminAssignmentDto extends EntitlementAssignmentDto {
	@JsonProperty({ type: Number })
	entitlementTypeAssociationId!: number;

	@JsonProperty({ type: Number })
	matrixVersionId!: number;

	@JsonProperty({ type: String })
	entitlementTypeName!: string;
}

export class CreateMatrixDto {
	headquarterId!: number;
	endDate: Date | null = null;
	startDate!: Date;
	createBlank: boolean = false;

	constructor(data: Partial<CreateMatrixDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class UpdateMatrixDto {
	startDate!: Date;
	endDate: Date | null = null;
	forceSave: boolean = false;

	constructor(data: Partial<UpdateMatrixDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementIssuanceTypeDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;
}


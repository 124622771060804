import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, ColumnSortation, PagedQueryParameter, PaginationModel, TableColumn } from '@nstep-common/core';
import { DropdownOption, ModalComponent, SearchComponent } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import {
	HeadquarterAssociation,
	HeadquarterExtendedModel,
	ChangeDateTypeModal,
	NationWithHqDtos,
	PosLocationAssociationDto,
	PosLocationEntitlementTypeAssociation,
	PosLocationHeadquarter,
	PosLocationInformationDto,
	PosLocationModel,
	PosLocationModelValidator,
	PosLocationSteps,
	PosLocationType,
	PosOperatorDto,
	PosTypeAssociationDto,
	SubRoleDto,
	AmisCardDto,
	PosOperatorAssociation,
	PosLocationDto,
	PosoperatorContactDto,
	TypeGroupedByHqDto,
	RationedType
} from '@nstep-internal/pages';
import { EntitlementTypeService, HeadquarterService, NationService, PosLocationService, UserService } from '@nstep-internal/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten, startCase } from 'lodash';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-pos-locations',
	templateUrl: './pos-locations.component.html'
})
export class PosLocationsComponent extends BaseComponent implements OnInit {

	PosLocationSteps = PosLocationSteps;
	ChangeDateTypeModal = ChangeDateTypeModal;

	@ViewChild('posLocationModal') posLocationModal!: ModalComponent;
	@ViewChild('commonModifyDateModal') commonModifyDateModal!: ModalComponent;
	@ViewChild('entitlementTypeModal') entitlementTypeModal!: ModalComponent;
	@ViewChild('posOperatorEnrollmentModal') posOperatorEnrollmentModal!: ModalComponent;
	@ViewChild('addPosOperatorContactModal') addPosOperatorContactModal!: ModalComponent;
	@ViewChild('posOperatorContactConfirmModal') posOperatorContactConfirmModal!: ModalComponent;

	@ViewChild('posLocationSearch') posLocationSearch!: SearchComponent;

	confirmMessage = '';

	mainModalIsLoading = false;
	sideModalIsLoading = false;
	editModal = false;
	noMainModalClose = false;
	editContactModal = false;

	currentStep = PosLocationSteps.PosLocationInformation;

	posLocationsData!: PosLocationInformationDto[];

	selectedPosLocation: PosLocationInformationDto = {} as PosLocationInformationDto;
	entitlementTypesHqDictionary!: TypeGroupedByHqDto;
	posOperatorWithPrimaryContact!: PosOperatorAssociation | undefined;

	mainTableData: any[] = [];
	mainTableDataReady = false;

	mainTableColumns: TableColumn[] = [
		{ name: 'Name', key: 'name', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Telephone', key: 'telephone', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Street Name', key: 'streetName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'City', key: 'city', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Country', key: 'nation.nationName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Action', isCellCentered: true, isHeaderCentered: true }
	];

	hqAssociationsTableData: any[] = [];
	hqAssociationsTableDataReady = false;

	hqAssociationsTableColumns: TableColumn[] = [
		{ name: 'Selected HQs', key: 'headquarterName', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Start Date', key: 'startDate', isCellCentered: true, isHeaderCentered: true },
		{ name: 'End Date', key: 'endDate', isCellCentered: true, isHeaderCentered: true }
	];

	entitlementTypesTableData: any[] = [];
	entitlementTypesTableDataReady = false;

	entitlementTypesTableColumns: TableColumn[] = [
		{ name: 'Entitlement Type', key: 'entitlementTypeName', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Headquarter', key: 'headquarterName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Rationed', key: 'rationingTypeId', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Action', isCellCentered: true, isHeaderCentered: true }
	];

	posOperatorsTableData: any[] = [];
	posOperatorsTableDataReady = false;

	posOperatorsTableColumns: TableColumn[] = [
		{ name: 'AMIS Card number', key: 'amisCardNumber', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Start Date', key: 'startDate', isCellCentered: true, isHeaderCentered: true },
		{ name: 'End Date', key: 'endDate', isCellCentered: true, isHeaderCentered: true }
	];

	posOperatorsFasScanTableColumns: TableColumn[] = [
		{ name: 'Fast Scan', key: 'isFastScan', isCellCentered: true, isHeaderCentered: true },
		{ name: 'AMIS Card number', key: 'amisCardNumber', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Start Date', key: 'startDate', isCellCentered: true, isHeaderCentered: true },
		{ name: 'End Date', key: 'endDate', isCellCentered: true, isHeaderCentered: true }
	];

	posLocationFastScanLocked = false;

	posOperatorsFastScanChecked = false;
	posOperatorsFastScanLocked = false;

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: 'name',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	today = new Date(new Date().toDateString());
	tomorrow = new Date(new Date(new Date().toDateString()).setDate(this.today.getDate() + 1));

	calendarSettings = {
		type: 'date',
		minDate: this.today
	};

	workingHoursCalendarSettings = {
		type: 'time',
		formatter: {
			time: 'H:mm',
			cellTime: 'H:mm'
		}
	};

	searchSettings = {
		apiSettings: {
			mockResponseAsync: (settings: any, callback: any) => {
				if (settings.urlData.query) {
					this.subscriptions.push(
						this.posLocationService.getAmisCards(settings.urlData.query).subscribe({
							next: response => {
								callback(response);
							}
						})
					);
				}
			},
		},
		fields: {
			title: 'cardNumber'
		},
		minCharacters: 3
	};

	allNations: NationWithHqDtos[] = [];
	allHeadquarterPosLocations: HeadquarterExtendedModel[] = [];
	subroles: SubRoleDto[] = [];

	countryDropdownValues: DropdownOption[] = [];
	posLocationTypes: PosLocationType[] = [];

	headquarterDropdownValues: DropdownOption[] = [];
	headuarters: PosLocationHeadquarter[] = [];

	entitlementTypesHqDropdownValues: DropdownOption[] = [];
	entitlementTypesDropdownValues: DropdownOption[] = [];

	selectedHeadquarter: string | null = null;
	dateTypeModal = ChangeDateTypeModal.Assign;
	selectedHeadquarterAssociation!: HeadquarterAssociation;
	selectedPosOperator: PosOperatorAssociation = {} as PosOperatorAssociation;

	isPosLocationModelValid = false;

	posLocationModel: PosLocationModel = createProxy(new PosLocationModel(), {
		set: () => this.validatePosLocationModel(this.posLocationModel)
	});

	posLocationModelValidation: ValidationErrors<PosLocationModel> = {};

	validatePosLocationModel(value: PosLocationModel): void {
		const validator = new PosLocationModelValidator();
		this.posLocationModelValidation = validator.validate(value);
		this.isPosLocationModelValid = Object.keys(this.posLocationModelValidation).length === 0;
	}

	constructor(private posLocationService: PosLocationService,
		private userService: UserService,
		private nationService: NationService,
		private headquarterService: HeadquarterService,
		private entitlementTypeService: EntitlementTypeService) {
		super();
	}

	ngOnInit(): void {
		this.posLocationModel.groupPropertiesValidation = this.currentStep;
		this.posLocationModel.posLocationTypeIds = [];
		this.posLocationModel.headquarterAssociations = [];
		this.posLocationModel.entitlementTypesAssociations = [];
		this.posLocationModel.posOperatorsAssociations = [];

		this.initializeTable();
	}

	search(): void {
		this.pagedQueryModel.page = 1;
		this.initializeTable();
	}

	openAddPosLocationModal(item: PosLocationInformationDto | null = null): void {
		this.currentStep = PosLocationSteps.PosLocationInformation;
		this.editModal = item ? true : false;

		this.mainModalIsLoading = true;
		this.posLocationModal.toggle();

		this.hqAssociationsTableDataReady = false;
		this.hqAssociationsTableData = [];

		this.entitlementTypesTableDataReady = false;
		this.entitlementTypesTableData = [];

		this.posOperatorsTableDataReady = false;
		this.posOperatorsTableData = [];

		if (!this.editModal) {

			this.posLocationTypes.forEach(item => {
				item.isChecked = false;
			});

			this.posLocationModel.groupPropertiesValidation = this.currentStep;
			this.posLocationModel.posLocationName = '';
			this.posLocationModel.countryId = null;
			this.posLocationModel.streetName = '';
			this.posLocationModel.buildingNumber = null;
			this.posLocationModel.postalCode = '';
			this.posLocationModel.cityOrTown = '';
			this.posLocationModel.headquarterId = this.headquarterDropdownValues[0].value;
			this.posLocationModel.isFastScan = false;
			this.posLocationFastScanLocked = false;

			this.posLocationModel.posLocationTypeIds = [];
			this.posLocationModel.headquarterAssociations = [];
			this.posLocationModel.entitlementTypesAssociations = [];
			this.posLocationModel.posOperatorsAssociations = [];

			this.posLocationModel.openingHour = null;
			this.posLocationModel.closingHour = null;

			this.posLocationModel.localOpeningHour = null;
			this.posLocationModel.localClosingHour = null;

			this.refreshCountryDropdownValues();
			this.refreshHeadquarterDropdownValues();

			this.hqAssociationsTableDataReady = true;
			this.entitlementTypesTableDataReady = true;
			this.posOperatorsTableDataReady = true;

			this.mainModalIsLoading = false;
		} else {

			this.selectedPosLocation = item!;

			this.posLocationModel.id = item!.id;
			this.posLocationModel.groupPropertiesValidation = this.currentStep;
			this.posLocationModel.posLocationName = item!.name;
			this.posLocationModel.countryId = item!.nationId;
			this.posLocationModel.telephone = item!.telephone;
			this.posLocationModel.streetName = item!.streetName;
			this.posLocationModel.buildingNumber = item!.buildingNumber;
			this.posLocationModel.postalCode = item!.postalCode;
			this.posLocationModel.cityOrTown = item!.city;
			this.posLocationModel.headquarterId = this.headquarterDropdownValues[0].value;
			this.posLocationModel.isFastScan = item!.isFastScan;

			this.posLocationModel.posLocationTypeIds = [];
			this.posLocationModel.headquarterAssociations = [];
			this.posLocationModel.entitlementTypesAssociations = [];
			this.posLocationModel.posOperatorsAssociations = [];

			this.posLocationModel.openingHour = this.getGMTDate(item!.openingHour);
			this.posLocationModel.closingHour = this.getGMTDate(item!.closingHour);

			this.posLocationModel.localOpeningHour = this.getGMTDate(item!.openingHour, true);
			this.posLocationModel.localClosingHour = this.getGMTDate(item!.closingHour, true);

			this.countryDropdownValues = chain(this.allNations)
				.map(e => new DropdownOption({
					value: e.id,
					name: e.nationName
				}))
				.orderBy(e => e.name)
				.value();

			this.refreshHeadquarterDropdownValues();

			const requests = [
				this.posLocationService.getSubRole(item!.id),
				this.posLocationService.getHqAssociations(item!.id),
				this.posLocationService.getPosTypeAssociations(item!.id),
				this.posLocationService.getPosOperators(item!.id)
			];

			this.subscriptions.push(
				forkJoin(requests).subscribe({
					next: (responses: any) => {

						const tomorrow = new Date();
						tomorrow.setDate(tomorrow.getDate() + 1);

						this.handleSubRoleResult(responses[0]);
						this.handleHqAssociationsResult(responses[1], tomorrow);
						this.handlePosTypeAssociationsResult(responses[2], item!.isFastScan);
						this.handlePosOperatorsResult(responses[3], tomorrow);

						this.mainModalIsLoading = false;
					},
					error: () => {
						this.mainModalIsLoading = false;
					}
				})
			);

		}
	}

	private refreshHeadquarterDropdownValues(): void {
		this.headquarterDropdownValues = chain(this.allHeadquarterPosLocations)
			.map(e => new DropdownOption({
				value: e.id,
				name: e.name
			}))
			.value();
	}

	handleSubRoleResult(resp: SubRoleDto[]): void {
		this.posLocationModel.posLocationTypeIds = [...resp.flatMap(r => r.id)];

		this.posLocationTypes.forEach(item => {
			item.isChecked = this.posLocationModel.posLocationTypeIds.includes(item.id);
		});
	}

	handleHqAssociationsResult(resp: PosLocationAssociationDto[], tomorrow: Date): void {
		const hqAssociations = chain(resp)
			.map(e => new HeadquarterAssociation({
				informationId: e.poslocationInformationId,
				headquarterId: e.headquarterId,
				headquarterName: e.headquarterName,
				startDate: new Date(new Date(e.startDate).toDateString()),
				endDate: new Date(new Date(e.endDate).toDateString()),
				canModify: new Date(new Date(e.startDate).toDateString()) > new Date(tomorrow.toDateString())
			}))
			.orderBy(d => d.headquarterName)
			.value();

		this.posLocationModel.headquarterAssociations = hqAssociations;

		this.hqAssociationsTableData = hqAssociations;
		this.hqAssociationsTableDataReady = true;
	}

	handlePosTypeAssociationsResult(resp: PosTypeAssociationDto[], posLocationFastScan: boolean): void {
		const posTypeAssociations = chain(resp)
			.map(e => new PosLocationEntitlementTypeAssociation({
				posLocationId: e.posLocationId,
				entitlementTypeId: e.entitlementTypeId,
				entitlementTypeName: e.entitlementType.name,
				disabled: e.entitlementType.disabled,
				rationingTypeId: e.entitlementType.rationingTypeId,
				headquarterId: e.headquarter.id,
				headquarterName: e.headquarter.name
			}))
			.orderBy(d => d.entitlementTypeName)
			.value();

		this.posLocationModel.entitlementTypesAssociations = posTypeAssociations;

		//fast scan is editable if all types assigned are non-rationed
		this.posLocationFastScanLocked = posLocationFastScan && this.posLocationModel.entitlementTypesAssociations.some(a => a.rationingTypeId === RationedType.Rationed);

		this.entitlementTypesTableData = [...this.posLocationModel.entitlementTypesAssociations];
		this.entitlementTypesTableDataReady = true;
	}

	handlePosOperatorsResult(resp: PosOperatorDto[], tomorrow: Date): void {

		this.posLocationModel.posOperatorsAssociations = chain(resp)
			.map(e => new PosOperatorAssociation({
				id: e.id,
				amisCardNumber: e.amiscard,
				amisCardId: e.amiscardId,
				poslocationId: e.poslocationId,
				startDate: e.startDate,
				endDate: e.endDate,
				endDateInThePast: e.endDate && new Date(new Date(e.endDate).toDateString()) < this.today ? true : false,
				canModify: new Date(new Date(e.startDate).toDateString()) > new Date(tomorrow.toDateString()),
				contact: e.contact,
				isActive: this.checkForActivePosOperator(e.startDate, e.endDate),
				isFastScan: e.isFastScan
			}))
			.orderBy(d => d.amisCardNumber)
			.value();

		this.posOperatorsFastScanLocked = this.posLocationModel.posOperatorsAssociations.some(poa => poa.isFastScan);
		this.posOperatorsFastScanChecked = this.posLocationModel.posOperatorsAssociations.some(poa => poa.isFastScan);

		this.posOperatorsTableData = [...this.posLocationModel.posOperatorsAssociations];
		this.posOperatorsTableDataReady = true;
	}

	changeStep(step: PosLocationSteps): void {
		if (!this.editModal) return;
		if (this.currentStep === step) return;

		const invalidFields = this.getInvalidFields();

		if (invalidFields) {
			toast('', `Form is not valid. Please provide valid data for the the following fields: ${invalidFields.join(', ')}`, 'red');
			return;
		}

		this.currentStep = step;
		this.posLocationModel.groupPropertiesValidation = step;
	}

	onCountryValueChange(): void {
		this.posLocationModel.localOpeningHour = this.getAdjustedLocalDate(this.posLocationModel.openingHour);
		this.posLocationModel.localClosingHour = this.getAdjustedLocalDate(this.posLocationModel.closingHour);
	}

	onPosLocationTypeValueChange(type: PosLocationType): void {
		if (type.isChecked) {
			this.posLocationModel.posLocationTypeIds.push(type.id);
		} else {
			const index = this.posLocationModel.posLocationTypeIds.findIndex(d => d === type.id);

			if (index !== -1) {
				this.posLocationModel.posLocationTypeIds.splice(index, 1);
			}
		}
	}

	onOpeningHourValueChange(): void {
		this.posLocationModel.localOpeningHour = this.getAdjustedLocalDate(this.posLocationModel.openingHour);
	}

	onClosingHourValueChange(): void {
		this.posLocationModel.localClosingHour = this.getAdjustedLocalDate(this.posLocationModel.closingHour);
	}

	onHeadquarterValueChange(): void {
		this.selectedHeadquarter = this.headquarterDropdownValues.find(hq => hq.value === this.posLocationModel.headquarterId)!.name;
	}

	checkCurrentAssociation(): void {
		const associationExists = this.posLocationModel.headquarterAssociations.some(d => d.headquarterId === Number(this.posLocationModel.headquarterId));

		if (associationExists) {
			toast('', 'The selected HQ is already in the list!', 'orange');
			return;
		} else {
			this.noMainModalClose = true;
			this.dateTypeModal = ChangeDateTypeModal.Assign;

			this.posLocationModel.groupPropertiesValidation = PosLocationSteps.HeadquarterAssociation;
			this.posLocationModel.commonDateHolder = null;
			this.commonModifyDateModal.toggle();
		}
	}

	closeCommonModifyDateModal(type: ChangeDateTypeModal): void {

		switch (type) {
			case ChangeDateTypeModal.Disable:
			case ChangeDateTypeModal.Assign:
			case ChangeDateTypeModal.ModifyHqDate:
			case ChangeDateTypeModal.Revoke:
				this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosLocationInformation;
				break;
			case ChangeDateTypeModal.ModifyPosOperatorDate:
			case ChangeDateTypeModal.Unenroll:
				this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosOperators;
				break;
		}

		this.posLocationModel.commonDateHolder = null;
		this.commonModifyDateModal.toggle();
		this.noMainModalClose = false;
	}

	assignHeadquarter(): void {

		this.validatePosLocationModel(this.posLocationModel);

		if (Object.keys(this.posLocationModelValidation).length != 0) {
			return;
		}

		const hqAssociation = new HeadquarterAssociation({
			headquarterId: this.posLocationModel.headquarterId,
			headquarterName: this.headquarterDropdownValues.find(hq => hq.value === this.posLocationModel.headquarterId)!.name,
			startDate: new Date(this.posLocationModel.commonDateHolder!.toDateString()),
			isNew: true
		});

		this.posLocationModel.headquarterAssociations.push(hqAssociation);

		this.hqAssociationsTableData = JSON.parse(JSON.stringify(this.posLocationModel.headquarterAssociations));

		this.commonModifyDateModal.toggle();
		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosLocationInformation;
		this.noMainModalClose = false;
		this.posLocationModel.commonDateHolder = null;
	}

	onPosLocationFastScanValueChange(event: any): void {
		if (this.posLocationFastScanLocked) return;

		const checkbox = event.target;

		this.posLocationModel.isFastScan = checkbox.checked;
		this.posLocationModel.posOperatorsAssociations.forEach(poa => { poa.isModified = this.posLocationModel.isFastScan === false && poa.isFastScan ? true : false; poa.isFastScan = false; });
		this.posOperatorsFastScanLocked = false;
		this.posOperatorsFastScanChecked = false;
		this.posOperatorsTableData = [...this.posLocationModel.posOperatorsAssociations];
	}

	removeElement(item: HeadquarterAssociation | PosLocationEntitlementTypeAssociation | PosOperatorAssociation, type: string): void {
		if (type === 'Hq') {
			const index = this.posLocationModel.headquarterAssociations.indexOf(item as HeadquarterAssociation);
			this.posLocationModel.headquarterAssociations.splice(index, 1);

			this.hqAssociationsTableData = [...this.posLocationModel.headquarterAssociations];
		}

		if (type === 'EntitlementType') {
			const index = this.posLocationModel.entitlementTypesAssociations.indexOf(item as PosLocationEntitlementTypeAssociation);
			this.posLocationModel.entitlementTypesAssociations.splice(index, 1);

			this.entitlementTypesTableData = [...this.posLocationModel.entitlementTypesAssociations];
		}

		if (type === 'Enroll') {
			const index = this.posLocationModel.posOperatorsAssociations.indexOf(item as PosOperatorAssociation);
			this.posLocationModel.posOperatorsAssociations.splice(index, 1);

			this.posOperatorsTableData = [...this.posLocationModel.posOperatorsAssociations];
		}

		if (type === 'Contact') {
			const index = this.posLocationModel.posOperatorsAssociations.indexOf(item as PosOperatorAssociation);
			this.posLocationModel.posOperatorsAssociations[index].contact = null;

			if (!(item as PosOperatorAssociation).isNew) {

				(item as PosOperatorAssociation).isModified = true;

				if (!(item as PosOperatorAssociation).contact) (item as PosOperatorAssociation).contactDeleted = true;
			}
		}
	}

	openModifyDateModal(item: HeadquarterAssociation): void {
		this.dateTypeModal = ChangeDateTypeModal.ModifyHqDate;
		this.noMainModalClose = true;

		this.selectedHeadquarterAssociation = item;

		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.HeadquarterAssociation;
		this.posLocationModel.commonDateHolder = item.startDate;
		this.commonModifyDateModal.toggle();
	}

	saveHqDate(dateName: string): void {
		this.validatePosLocationModel(this.posLocationModel);

		if (Object.keys(this.posLocationModelValidation).length != 0) {
			return;
		}

		this.selectedHeadquarterAssociation.isModified = true;

		const index = this.posLocationModel.headquarterAssociations.findIndex(hqa => hqa.headquarterId === this.selectedHeadquarterAssociation.headquarterId);

		const formatedDate = new Date(this.posLocationModel.commonDateHolder!.toDateString());
		if (dateName === 'startDate') {
			this.posLocationModel.headquarterAssociations[index].startDate = formatedDate;
		} else {
			this.posLocationModel.headquarterAssociations[index].endDate = formatedDate;
		}

		this.hqAssociationsTableData = JSON.parse(JSON.stringify(this.posLocationModel.headquarterAssociations));

		this.commonModifyDateModal.toggle();
		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosLocationInformation;
		this.noMainModalClose = false;
		this.posLocationModel.commonDateHolder = null;
	}

	openRevokeHqModal(item: HeadquarterAssociation): void {
		this.dateTypeModal = ChangeDateTypeModal.Revoke;
		this.noMainModalClose = true;

		this.selectedHeadquarterAssociation = item;

		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.HeadquarterAssociation;
		this.posLocationModel.commonDateHolder = null;
		this.commonModifyDateModal.toggle();
	}

	saveDate(): void {
		switch (this.dateTypeModal) {
			case ChangeDateTypeModal.Disable:
				this.disable();
				break;
			case ChangeDateTypeModal.Assign:
				this.assignHeadquarter();
				break;
			case ChangeDateTypeModal.ModifyHqDate:
				this.saveHqDate('startDate');
				break;
			case ChangeDateTypeModal.Revoke:
				this.saveHqDate('endDate');
				break;
			case ChangeDateTypeModal.ModifyPosOperatorDate:
				this.savePosOperatorDate('startDate');
				break;
			case ChangeDateTypeModal.Unenroll:
				this.savePosOperatorDate('endDate');
				break;
		}
	}

	openEntitlementTypeAssignmentModal(): void {
		this.noMainModalClose = true;
		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.AssignEntitlementTypes;

		this.entitlementTypesHqDropdownValues = chain(this.posLocationModel.headquarterAssociations)
			.map(e => new DropdownOption({
				value: e.headquarterId,
				name: e.headquarterName,
			}))
			.value();

		this.posLocationModel.entitlementTypeHeadquarterId = this.posLocationModel.headquarterAssociations[0].headquarterId;

		this.populateEntitlementTypesDropdownValues();
		this.posLocationModel.entitlementTypeId = null;

		this.entitlementTypeModal.toggle();
	}

	closeEntitlementTypeAssignmentModal(): void {
		this.entitlementTypeModal.toggle();
		this.noMainModalClose = false;
		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.EntitlementTypes;
	}

	onEntitlementTypedHqValueChange(): void {
		this.posLocationModel.entitlementTypeId = null;
		this.populateEntitlementTypesDropdownValues();
	}

	private populateEntitlementTypesDropdownValues(): void {
		this.entitlementTypesDropdownValues = chain(this.entitlementTypesHqDictionary.types[this.posLocationModel.entitlementTypeHeadquarterId!])
			.filter(e => !this.entitlementTypesTableData.some(d => d.entitlementTypeId === e.id && d.headquarterId === this.posLocationModel.entitlementTypeHeadquarterId))
			.map(e => new DropdownOption({
				value: e.id,
				name: e.name
			}))
			.value();
	}

	assignEntitlementTypeHq(): void {

		const currentEntitlementType = this.entitlementTypesHqDictionary.types[this.posLocationModel.entitlementTypeHeadquarterId!].find(e => e.id === this.posLocationModel.entitlementTypeId)!;
		const areAllEntitlementAssociationsNonRationed = this.posLocationModel.entitlementTypesAssociations.every(a => a.rationingTypeId === RationedType.NonRationed);

		if (this.posLocationModel.isFastScan && currentEntitlementType.rationingTypeId === RationedType.Rationed && areAllEntitlementAssociationsNonRationed) {
			this.posLocationModel.isFastScan = false;

			this.posLocationModel.posOperatorsAssociations.forEach(poa => { poa.isModified = this.posLocationModel.isFastScan === false && poa.isFastScan ? true : false; poa.isFastScan = false; });
			this.posOperatorsFastScanChecked = false;
			this.posOperatorsFastScanLocked = false;
			this.posOperatorsTableData = [...this.posLocationModel.posOperatorsAssociations];

			toast('Warning', 'Fast scan was disabled due to rationed entitlement type addition.', 'orange');
		}

		const currentHeadquarter = this.posLocationModel.headquarterAssociations.find(d => d.headquarterId === this.posLocationModel.entitlementTypeHeadquarterId!)!;

		const entitlementTypeAssociation = new PosLocationEntitlementTypeAssociation({
			posLocationId: null,
			entitlementTypeId: currentEntitlementType.id,
			entitlementTypeName: currentEntitlementType.name,
			disabled: currentEntitlementType.disabled,
			rationingTypeId: currentEntitlementType.rationingTypeId,
			headquarterId: currentHeadquarter.headquarterId,
			headquarterName: currentHeadquarter.headquarterName!
		});

		this.posLocationModel.entitlementTypesAssociations.push(entitlementTypeAssociation);

		this.entitlementTypesTableData = [...this.posLocationModel.entitlementTypesAssociations];
		this.entitlementTypesTableData = chain(this.entitlementTypesTableData)
			.orderBy(d => d.entitlementTypeName)
			.value();

		this.entitlementTypeModal.toggle();
		this.noMainModalClose = false;
		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.EntitlementTypes;
	}

	onPosOperatorFastScanValueChange(item: PosOperatorAssociation, event: any): void {
		if (this.posOperatorsFastScanLocked) return;

		const checkbox = event.target;
		this.posLocationModel.posOperatorsAssociations.forEach(poa => poa.isFastScan = false);

		const index = this.posLocationModel.posOperatorsAssociations.indexOf(item);

		const currentPosOperatorAssociation = this.posLocationModel.posOperatorsAssociations[index];
		currentPosOperatorAssociation.isFastScan = checkbox.checked;
		currentPosOperatorAssociation.isModified = !currentPosOperatorAssociation.isNew;

		this.posOperatorsFastScanChecked = this.posLocationModel.posOperatorsAssociations.some(poa => poa.isFastScan);

		this.posOperatorsTableData = [...this.posLocationModel.posOperatorsAssociations];
	}

	openPosOperatorModifyDateModal(item: PosOperatorAssociation): void {
		this.dateTypeModal = ChangeDateTypeModal.ModifyPosOperatorDate;
		this.noMainModalClose = true;

		this.selectedPosOperator = item;

		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.HeadquarterAssociation;
		this.posLocationModel.commonDateHolder = null;
		this.commonModifyDateModal.toggle();
	}

	openUnenrollPosOperatorModal(item: PosOperatorAssociation): void {
		this.dateTypeModal = ChangeDateTypeModal.Unenroll;
		this.noMainModalClose = true;

		this.selectedPosOperator = item;

		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.HeadquarterAssociation;
		this.posLocationModel.commonDateHolder = item.endDate;
		this.commonModifyDateModal.toggle();
	}

	savePosOperatorDate(dateName: string): void {
		this.validatePosLocationModel(this.posLocationModel);

		if (Object.keys(this.posLocationModelValidation).length != 0) {
			return;
		}

		this.selectedPosOperator.isModified = true;

		const index = this.posLocationModel.posOperatorsAssociations.indexOf(this.selectedPosOperator);
		const currentPosOperatorAssociation = this.posLocationModel.posOperatorsAssociations[index];

		const formatedDate = new Date(this.posLocationModel.commonDateHolder!.toDateString());

		if (dateName === 'startDate') {
			currentPosOperatorAssociation.startDate = formatedDate;
		} else {
			currentPosOperatorAssociation.endDate = formatedDate;
			currentPosOperatorAssociation.endDateInThePast = formatedDate < this.today;
		}

		currentPosOperatorAssociation.isActive = this.checkForActivePosOperator(currentPosOperatorAssociation.startDate, currentPosOperatorAssociation.endDate);

		this.posOperatorsTableData = [... this.posLocationModel.posOperatorsAssociations];

		this.commonModifyDateModal.toggle();
		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosOperators;
		this.noMainModalClose = false;
		this.posLocationModel.commonDateHolder = null;
	}

	openPosOperatorEnrollmentModal(): void {
		this.noMainModalClose = true;

		this.posOperatorWithPrimaryContact = this.posLocationModel.posOperatorsAssociations.find(poa => poa.contact && poa.contact.isPrimary);
		this.posLocationModel.noPrimaryContactSelected = this.posOperatorWithPrimaryContact ? false : true;

		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.EnrollPosOperator;
		this.posOperatorEnrollmentModal.toggle();
	}

	onAmisCardValueChange(value: AmisCardDto): void {
		this.posLocationModel.amisCardNumber = value?.cardNumber;
		this.posLocationModel.amisCardId = value?.id;
	}

	closePosOperatorEnrollmentModal(): void {
		this.posOperatorEnrollmentModal.toggle();
		this.noMainModalClose = false;

		this.posLocationModel.displayPOCForm = false;

		this.posLocationModel.amisCardNumber = null;
		this.posLocationModel.amisCardId = null;
		this.posLocationModel.posOperatorStartDate = null;
		this.posLocationModel.posOperatorEndDate = null;

		this.posLocationModel.posOperatorContactName = null;
		this.posLocationModel.posOperatorContactEmail = null;
		this.posLocationModel.posOperatorContactPhoneNumber = null;
		this.posLocationModel.isPrimaryPosOperatorContact = false;

		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosOperators;
	}

	enrollPosOperator(): void {

		const alreadyAssociated = this.posLocationModel.posOperatorsAssociations.some(poa => poa.amisCardId === this.posLocationModel.amisCardId!);

		if (alreadyAssociated) {
			toast('', 'The selected POS Operator is already associated.', 'red');
			return;
		}

		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);

		const posOperatorItem = new PosOperatorAssociation({
			amisCardNumber: this.posLocationModel.amisCardNumber!,
			amisCardId: this.posLocationModel.amisCardId!,
			startDate: this.posLocationModel.posOperatorStartDate!,
			endDate: this.posLocationModel.posOperatorEndDate,
			canModify: new Date(new Date(this.posLocationModel.posOperatorStartDate!).toDateString()) > new Date(tomorrow.toDateString()),
			contact: this.posLocationModel.displayPOCForm ? new PosoperatorContactDto({
				name: this.posLocationModel.posOperatorContactName!,
				email: this.posLocationModel.posOperatorContactEmail!,
				phoneNumber: this.posLocationModel.posOperatorContactPhoneNumber!,
				isPrimary: this.posLocationModel.isPrimaryPosOperatorContact
			}) : null,
			isNew: true
		});

		this.posLocationModel.posOperatorsAssociations.push(posOperatorItem);
		this.posOperatorsTableData = chain(this.posLocationModel.posOperatorsAssociations)
			.orderBy(d => d.amisCardNumber)
			.value();

		this.closePosOperatorEnrollmentModal();
	}

	back(): void {

		const invalidFields = this.getInvalidFields();

		if (invalidFields) {
			toast('', `Form is not valid. Please provide valid data for the the following fields: ${invalidFields.join(', ')}`, 'red');
			return;
		}

		this.currentStep = this.currentStep === PosLocationSteps.PosOperators ? PosLocationSteps.EntitlementTypes : PosLocationSteps.PosLocationInformation;
		this.posLocationModel.groupPropertiesValidation = this.currentStep;
	}

	next(): void {

		const invalidFields = this.getInvalidFields();

		if (invalidFields) {
			toast('', `Form is not valid. Please provide valid data for the the following fields: ${invalidFields.join(', ')}`, 'red');
			return;
		}

		this.currentStep = this.currentStep === PosLocationSteps.PosLocationInformation ? PosLocationSteps.EntitlementTypes : PosLocationSteps.PosOperators;
		this.posLocationModel.groupPropertiesValidation = this.currentStep;
	}

	save(): void {

		const invalidFields = this.getInvalidFields();

		if (invalidFields) {
			toast('', `Form is not valid. Please provide valid data for the the following fields: ${invalidFields.join(', ')}`, 'red');
			return;
		}

		const invalidPosOperatorFastScan = this.posLocationModel.posOperatorsAssociations.some(poa => poa.isFastScan && !poa.isActive);

		if (invalidPosOperatorFastScan) {
			toast('', 'The associated Pos Operator is not active for the current Fast Scan selection.', 'red');
			return;
		}

		const model = new PosLocationInformationDto({
			name: this.posLocationModel.posLocationName,
			nationId: this.posLocationModel.countryId,
			telephone: this.posLocationModel.telephone,
			streetName: this.posLocationModel.streetName,
			buildingNumber: this.posLocationModel.buildingNumber,
			postalCode: this.posLocationModel.postalCode,
			city: this.posLocationModel.cityOrTown,
			openingHour: this.getTimeString(this.posLocationModel.openingHour!),
			closingHour: this.getTimeString(this.posLocationModel.closingHour!),
			isFastScan: this.posLocationModel.isFastScan
		});

		switch (!this.editModal) {
			case true:

				this.subscriptions.push(
					this.posLocationService.savePosLocation(model).subscribe({
						next: posLocationResponse => {

							toast('', `POS Location Information ${this.posLocationModel.posLocationName} successfully created!`, 'green');
							this.saveSubRoles(posLocationResponse);
							this.saveHqAssociation(posLocationResponse);
							this.savePosOperators(posLocationResponse);

							this.mainTableDataReady = false;
							this.mainTableData = [];

							this.posLocationService.clearSubRoleCache(posLocationResponse.id);
							this.posLocationService.clearPosTypeAssociationsCache(posLocationResponse.id);
							this.posLocationService.clearHqAssociationsCache(posLocationResponse.id);
							this.posLocationService.clearPosOperatorsCache(posLocationResponse.id);
							this.posLocationService.clearPosLocationsCache();
							this.getPosLocations();
						},
						error: () => {
							toast('', `POS Location Information ${this.posLocationModel.posLocationName} could not be saved!`, 'red');
						}
					}));
				break;
			case false:

				model.id = this.posLocationModel.id!;

				this.subscriptions.push(
					this.posLocationService.updatePosLocation(model).subscribe({
						next: () => {

							toast('', `POS Location Information ${this.posLocationModel.posLocationName} successfully modified!`, 'green');

							this.mainTableDataReady = false;
							this.mainTableData = [];

							this.posLocationService.clearPosLocationsCache();
							this.getPosLocations();
						},
						error: () => {
							toast('', `POS Location Information ${this.posLocationModel.posLocationName} could not be modified!`, 'red');
						}
					})
				);

				this.saveSubRoles(model);
				this.saveHqAssociation(model);
				this.savePosOperators(model);
				this.savePosOperatorContact();

				this.posLocationService.clearSubRoleCache(model.id);
				this.posLocationService.clearPosTypeAssociationsCache(model.id);
				this.posLocationService.clearHqAssociationsCache(model.id);
				this.posLocationService.clearPosOperatorsCache(model.id);

				break;
		}

		this.posLocationModal.toggle();
	}

	private getInvalidFields(): string[] | null {
		this.validatePosLocationModel(this.posLocationModel);

		if (Object.keys(this.posLocationModelValidation).length === 0) {
			return null;
		} else {
			const fields: string[] = [];
			Object.keys(this.posLocationModelValidation).forEach(key => {
				fields.push(`"${startCase(key)}"`);
			});

			toast('', `Form is not valid. Please provide valid data for the the following fields: ${fields.join(', ')}`, 'red');

			return fields;
		}
	}

	saveSubRoles(posLocation: PosLocationInformationDto): void {
		const subrolesToSave = chain(this.subroles)
			.filter(s => this.posLocationModel.posLocationTypeIds.includes(s.id))
			.value();

		this.subscriptions.push(
			this.posLocationService.saveSubrole(posLocation.id, subrolesToSave).subscribe({
				next: () => {
					toast('', `${posLocation.name} Location Information SubRole Association successfully assigned!`, 'green');
				},
				error: () => {
					toast('', `${posLocation.name} SubRole Association : could not be saved!`, 'orange');
				}
			}));
	}

	saveEntitlementTypes(posLocationId: number): void {
		this.posLocationModel.entitlementTypesAssociations.forEach(element => {
			if (!element.posLocationId) {
				const model = {
					entitlementTypeId: element.entitlementTypeId
				};

				this.subscriptions.push(
					this.posLocationService.assignTypeToPOSLocation(posLocationId, element.headquarterId, model).subscribe({
						next: () => {
							toast('', `${element.entitlementTypeName} Entitlement Type successfully assigned!`, 'green');
						},
						error: () => {
							toast('', `${element.entitlementTypeName} Entitlement Type : could not be saved!`, 'orange');
						}
					})
				);
			} else if (element.disabled) {
				this.subscriptions.push(
					this.posLocationService.revokeTypeFromPOSLocation(element.posLocationId, element.entitlementTypeId).subscribe({
						next: () => {
							toast('', `${element.entitlementTypeName} Entitlement Type successfully disabled!`, 'green');
						},
						error: () => {

							toast('', `${element.entitlementTypeName} Entitlement Type : could not be disabled!`, 'orange');
						}
					})
				);
			}
		});
	}

	saveHqAssociation(posLocation: PosLocationInformationDto): void {

		let savedItemsCount = 0;

		const totalNewItems = this.posLocationModel.headquarterAssociations
			.filter(d => d.isNew)
			.length;

		if (totalNewItems === 0) this.saveEntitlementTypes(posLocation.id);

		this.posLocationModel.headquarterAssociations.forEach(hqa => {
			if (hqa.isNew) {
				const formattedStartDate = formatDate(hqa.startDate!, 'yyyy-MM-dd', 'en');

				this.subscriptions.push(
					this.posLocationService.assignPosLocationToHeadquarter(posLocation.id, hqa.headquarterId, formattedStartDate).subscribe({
						next: () => {
							savedItemsCount += 1;
							if (savedItemsCount === totalNewItems) this.saveEntitlementTypes(posLocation.id);

							toast('', `${hqa.headquarterName} Association : successfully created!`, 'green');
						},
						error: () => {
							toast('', `${hqa.headquarterName} Association : could not be saved!`, 'orange');
						}
					}));
			} else if (hqa.isModified) {

				const model: PosLocationDto = {
					id: hqa.informationId!,
					poslocationInformationId: posLocation.id,
					headquarterId: hqa.headquarterId,
					endDate: new Date(hqa.endDate!.toDateString()),
					startDate: new Date(hqa.startDate!.toDateString()),
					modifiedDate: new Date()
				};

				this.subscriptions.push(
					this.posLocationService.updatePosLocationHqAssociation(model).subscribe({
						next: () => {
							toast('', `${hqa.headquarterName} Association : successfully modified!`, 'green');
						},
						error: () => {
							toast('', `${hqa.headquarterName} Association : could not be modified!`, 'orange');
						}
					})
				);
			}
		});
	}

	savePosOperators(posLocation: PosLocationInformationDto): void {
		this.posLocationModel.posOperatorsAssociations.forEach(poa => {
			if (poa.isNew) {

				const model = new PosOperatorDto({
					id: 0,
					poslocationId: posLocation.id,
					isFastScan: poa.isFastScan,
					amiscardId: poa.amisCardId,
					amiscard: poa.amisCardNumber,
					startDate: poa.startDate,
					endDate: poa.endDate
				});

				this.subscriptions.push(
					this.posLocationService.enrollPosOperator(model).subscribe({
						next: response => {
							poa.id = response.id;

							if (poa.contact) this.assignPosOperatorContact(response.id, poa.contact, response.amiscard!);

							toast('', `${poa.amisCardNumber} POS Operator : successfully enrolled!`, 'green');
						},
						error: () => {
							toast('', `${poa.amisCardNumber} POS Operator : could not be saved!`, 'orange');
						}
					})
				);
			} else if (poa.isModified) {

				const model = new PosOperatorDto({
					id: poa.id!,
					poslocationId: posLocation.id,
					isFastScan: poa.isFastScan,
					amiscardId: poa.amisCardId,
					amiscard: poa.amisCardNumber,
					startDate: poa.startDate,
					endDate: poa.endDate
				});

				this.subscriptions.push(
					this.posLocationService.updatePosOperator(model).subscribe({
						next: () => {
							toast('', `${poa.amisCardNumber} POS Operator : successfully modified!`, 'green');
						},
						error: () => {
							toast('', `${poa.amisCardNumber} POS Operator : could not be modified!`, 'orange');
						}
					})
				);
			}
		});
	}

	savePosOperatorContact(): void {

		const posOperatorsContacts = chain(this.posLocationModel.posOperatorsAssociations)
			.map(e => ({
				posOperatorId: e.id!,
				amisCardNumber: e.amisCardNumber,
				contact: e.contact,
				isNew: e.isNew,
				isModified: e.isModified,
				contactDeleted: e.contactDeleted
			}))
			.value();

		posOperatorsContacts.forEach(poc => {
			if (poc.contact && !poc.contact.id && !poc.isNew) {

				poc.contact.id = 0;
				this.assignPosOperatorContact(poc.posOperatorId, poc.contact, poc.amisCardNumber!);

			} else if (poc.contact && poc.contact.id && poc.isModified) {

				this.subscriptions.push(
					this.posLocationService.updatePosOperatorContact(poc.posOperatorId, poc.contact).subscribe({
						next: () => {
							toast('', `${poc.amisCardNumber} POS Operator Contact : successfully modified!`, 'green');
						},
						error: () => {
							toast('', `${poc.amisCardNumber} POS Operator Contact : could not be modified!`, 'orange');
						}
					})
				);

			} else if (poc.contactDeleted) {

				this.subscriptions.push(
					this.posLocationService.deletePosOperatorContact(poc.posOperatorId).subscribe({
						next: () => {
							toast('', `${poc.amisCardNumber} POS Operator Contact : successfully deleted!`, 'green');
						},
						error: () => {
							toast('', `${poc.amisCardNumber} POS Operator Contact : could not be deleted!`, 'orange');
						}
					})
				);
			}
		});
	}

	assignPosOperatorContact(posOperatorId: number, contact: PosoperatorContactDto, amisCardNumber: string): void {
		this.posLocationService.assignPosOperatorContact(posOperatorId, contact).subscribe({
			next: () => {
				toast('', `${amisCardNumber} POS Operator Contact : successfully assgined!`, 'green');
			},
			error: () => {
				toast('', `${amisCardNumber} POS Operator Contact : could not be saved!`, 'orange');
			}
		});
	}

	openDisablePosLocationModal(item: PosLocationInformationDto): void {
		this.dateTypeModal = ChangeDateTypeModal.Disable;

		this.selectedPosLocation = item;

		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.HeadquarterAssociation;
		this.posLocationModel.commonDateHolder = null;
		this.commonModifyDateModal.toggle();
	}

	disable(): void {
		this.sideModalIsLoading = true;

		this.subscriptions.push(
			this.posLocationService.disablePosLocation(this.selectedPosLocation.id, formatDate(this.posLocationModel.commonDateHolder!, 'yyyy-MM-dd', 'en')).subscribe({
				next: () => {
					this.commonModifyDateModal.toggle();
					this.sideModalIsLoading = false;

					this.posLocationService.clearPosLocationsCache();

					this.mainTableDataReady = false;
					this.mainTableData = [];
					this.initializeTable();

					toast('', `POS Location ${this.selectedPosLocation.name} successfully disabled!`, 'green');
					this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosLocationInformation;
					this.posLocationModel.commonDateHolder = null;
				},
				error: (response) => {
					this.sideModalIsLoading = false;

					const errors: string[] = flatten(Object.values(response));
					toast('', `POS Location ${this.selectedPosLocation.name} disabling encountered a problem! ${errors[0]}`, 'red');
				}
			})
		);
	}

	getGMTDate(time: string, applyTimezoneOffset: boolean = false): Date | null {

		if (!this.posLocationModel.countryId) {
			return null;
		}

		const timeSegments = time.split(':').map(Number);

		const selectedNationTimeOffset = this.allNations.find(n => n.id === this.posLocationModel.countryId)!.timezoneOffset;

		const date = new Date();
		date.setHours(timeSegments[0] + (applyTimezoneOffset ? selectedNationTimeOffset : 0), timeSegments[1], timeSegments[2], 0);

		return date;
	}

	private checkForActivePosOperator(startDate: Date, endDate: Date | null = null): boolean {

		const today = new Date(new Date().toDateString());
		const startDateWithoutTime = new Date(new Date(startDate).toDateString());

		if (!endDate) {
			return today < startDateWithoutTime;
		}

		if (endDate) {

			const endDateWithoutTime = new Date(new Date(endDate).toDateString());

			if (today < startDateWithoutTime) {
				return false;
			}

			if (endDateWithoutTime < today) {
				return false;
			}
		}

		return true;
	}

	closePosOperatorContactModal(): void {
		this.addPosOperatorContactModal.toggle();
		this.noMainModalClose = false;
		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosOperators;
	}

	openAddContactModal(item: PosOperatorAssociation): void {
		this.editContactModal = item.contact ? true : false;
		this.noMainModalClose = true;

		this.posOperatorWithPrimaryContact = this.posLocationModel.posOperatorsAssociations.find(poa => poa.contact && poa.contact.isPrimary);
		this.posLocationModel.noPrimaryContactSelected = this.posOperatorWithPrimaryContact ? false : true;

		this.selectedPosOperator = item;

		if (!this.editContactModal) {
			this.posLocationModel.posOperatorContactName = null;
			this.posLocationModel.posOperatorContactEmail = null;
			this.posLocationModel.posOperatorContactPhoneNumber = null;
			this.posLocationModel.isPrimaryPosOperatorContact = false;

		} else {
			this.posLocationModel.posOperatorContactName = item.contact!.name;
			this.posLocationModel.posOperatorContactEmail = item.contact!.email;
			this.posLocationModel.posOperatorContactPhoneNumber = item.contact!.phoneNumber;
			this.posLocationModel.isPrimaryPosOperatorContact = item.contact!.isPrimary;
		}

		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosOperatorContact;
		this.addPosOperatorContactModal.toggle();
	}

	assginPosOperatorContact(): void {

		const invalidFields = this.getInvalidFields();

		if (invalidFields) {
			toast('', `Form is not valid. Please provide valid data for the the following fields: ${invalidFields.join(', ')}`, 'red');
			return;
		}

		const contact = new PosoperatorContactDto({
			name: this.posLocationModel.posOperatorContactName!,
			email: this.posLocationModel.posOperatorContactEmail!,
			phoneNumber: this.posLocationModel.posOperatorContactPhoneNumber!,
			isPrimary: this.posLocationModel.isPrimaryPosOperatorContact
		});

		if (this.editContactModal) contact.id = this.selectedPosOperator.contact!.id;

		this.selectedPosOperator.contact = contact;
		this.selectedPosOperator.isModified = true;

		this.addPosOperatorContactModal.toggle();
		this.noMainModalClose = false;
		this.posLocationModel.groupPropertiesValidation = PosLocationSteps.PosOperators;
	}

	openPOCConfirmationModal(item: PosOperatorAssociation): void {
		this.selectedPosOperator = item;
		this.confirmMessage = `Are you sure you want to delete contact information for ${item.amisCardNumber}`;
		this.posOperatorContactConfirmModal.toggle();
	}

	confirm(type: string) {
		this.posOperatorContactConfirmModal.toggle();
		this.removeElement(this.selectedPosOperator, type);
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.mainTableDataReady = false;
		this.mainTableData = [];

		this.initializeTable();
	}

	sortChange(col: ColumnSortation): void {
		this.pagedQueryModel.page = 1;
		this.pagedQueryModel.orderField = col.sortAsc !== null ? `${col.key} ${col.sortAsc ? 'asc' : 'desc'}` : '';

		this.mainTableDataReady = false;
		this.mainTableData = [];

		this.initializeTable();
	}

	private initializeTable(): void {

		this.subscriptions.push(
			this.userService.getSubRoles().subscribe({
				next: response => {

					this.subroles = response;

					this.posLocationTypes = chain(response)
						.map(e => new PosLocationType({
							id: e.id,
							name: e.name,
							isChecked: false
						}))
						.value();
				}
			})
		);

		this.subscriptions.push(
			this.nationService.getNations().subscribe({
				next: response => {

					this.allNations = response;
					this.refreshCountryDropdownValues();
				}
			})
		);

		this.subscriptions.push(
			this.headquarterService.getHeadquartersEx().subscribe({
				next: response => {

					this.allHeadquarterPosLocations = response;

					this.headquarterDropdownValues = chain(this.allHeadquarterPosLocations)
						.map((e) => new DropdownOption({
							value: e.id,
							name: e.name
						}))
						.value();

					const hqIds = this.allHeadquarterPosLocations.flatMap(d => d.id);

					this.subscriptions.push(
						this.entitlementTypeService.getEntitlementTypesByHq(hqIds).subscribe({
							next: (response: TypeGroupedByHqDto) => {

								this.entitlementTypesHqDictionary = response;
							}
						})
					);

					this.posLocationModel.headquarterId = this.headquarterDropdownValues[0].value;
					this.selectedHeadquarter = this.headquarterDropdownValues[0].name;
				}
			})
		);

		this.getPosLocations();
	}

	private refreshCountryDropdownValues(): void {
		this.countryDropdownValues = chain(this.allNations)
			.map(e => new DropdownOption({
				value: e.id,
				name: e.nationName
			}))
			.orderBy(e => e.name)
			.value();
	}

	private getPosLocations(): void {
		this.subscriptions.push(
			this.posLocationService.getPosLocations(this.pagedQueryModel).subscribe({
				next: response => {

					this.pagination = response.page;
					this.mainTableData = response.results;

					this.mainTableDataReady = true;
				},
				error: () => {
					this.mainTableDataReady = true;
				}
			})
		);
	}

	private getAdjustedLocalDate(value: Date | null): Date | null {
		if (!this.posLocationModel.countryId || !value) {
			return null;
		}

		const selectedNationTimeOffset = this.allNations.find(n => n.id === this.posLocationModel.countryId)!.timezoneOffset;

		const date = new Date();
		date.setHours(value.getHours() + selectedNationTimeOffset, value.getMinutes(), 0, 0);

		return date;
	}

	private getTimeString(date: Date): string {
		return date.toTimeString().split(' ')[0].slice(0, 8);
	}
}

<h3 class="ui header">
	<i class="project diagram icon"></i>
	<div class="content">
		Clients
		<div class="sub header">Membership</div>
	</div>
</h3>
<br />

<div class="ui stackable grid">
	<div class="row">
		<div class="three wide column">
		</div>
		<div class="thirteen wide column">
			<button class="ui right floated primary button"
				(click)="createClient()">
				<i class="plus icon"></i>Add Client
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="itemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #itemTemplate let-item="item">
	<tr>
		<td style="text-align: center;">
			<a href="javascript:void(0);"
				(click)="editClient(item)">
				{{item.name}}
			</a>
		</td>
		<td>{{item.description ?? 'N/A' | dash}}</td>
		<td style="text-align: center;">{{item.type}}</td>
		<td style="text-align: center;">{{item.validFrom | date | dash}}</td>
		<td style="text-align: center;">{{item.validTo | date | dash}}</td>
		<td style="text-align: center;">
			<div class="ui small toggle checkbox">
				<input type="checkbox"
					[(ngModel)]="item.active"
					(ngModelChange)="toggleClient(item)" />
				<label>{{item.active ? 'Yes' : 'No'}}</label>
			</div>
		</td>
		<td style="text-align: center;">
			<a href="javascript:void(0);"
				(click)="confirm('ask-delete', item)">
				Delete
			</a>
		</td>
	</tr>
</ng-template>

<ng-template #apiTree let-permissions="permissions">
	<div class="ui list">
		<div class="item" *ngFor="let perm of permissions">
			<ng-container *ngIf="(perm.children && perm.children.length) else renderLeaf">
				<strong class="ui text">{{perm.name}}</strong>
				<ng-template
					[ngTemplateOutlet]="apiTree"
					[ngTemplateOutletContext]="{ permissions: perm.children }">
				</ng-template>
			</ng-container>

			<ng-template #renderLeaf>
				<div class="ui checkbox">
					<input type="checkbox"
						[(ngModel)]="state[perm.value]" />
					<label>{{perm.name}}</label>
				</div>
			</ng-template>
		</div>
	</div>
</ng-template>

<sm-modal #upsertModal
	[isLoading]="waitingForServer"
	[loadingText]="'Saving'">
	<ng-container header>
		<i class="project diagram icon"></i> {{ client.id ? ("Edit Client: " + client.name + "") : "Create Client" }}
	</ng-container>

	<ng-container content>
		<div class="ui divided grid">
			<div class="row">
				<div [className]="(client.type == 2 ? 'eleven wide column' : 'sixteen wide column')">
					<div class="ui form">
						<div class="field" app-field [error]="validation | path: 'type'">
							<label>Type</label>
							<sm-dropdown class="ui fluid selection dropdown label"
								[(value)]="this.client.type"
								[options]="clientTypes">
								<div class="text"></div>
								<i class="dropdown icon"></i>
							</sm-dropdown>
						</div>

						<div class="field" app-field [error]="validation | path: 'name'">
							<label>Name</label>
							<input type="text" [(ngModel)]="client.name" app-trim />
						</div>

						<div class="field" app-field [error]="validation | path: 'description'">
							<label>Description</label>
							<input type="text" [(ngModel)]="client.description" app-trim />
						</div>

						<div class="fields">
							<div class="eight wide field" app-field [error]="validation | path: 'clientId'">
								<label>Client Id</label>
								<input type="text" [(ngModel)]="client.clientId" [readOnly]="true" app-trim />
							</div>

							<div class="eight wide field" app-field [error]="validation | path: 'clientSecret'">
								<label>Client Secret</label>

								<div class="ui action input">
									<input type="text" [(ngModel)]="client.clientSecret" [readOnly]="true" app-trim>
									<button class="ui icon button"
										(click)="regenerateClientSecret()">
										<i class="refresh icon"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="fields">
							<div class="eight wide field">
								<label>Valid From</label>
								<sm-calendar class="ui calendar"
									[settings]="calendarSettings"
									[(value)]="client.validFrom"
									[maxValue]="client.validTo">
									<div class="ui fluid input left icon">
										<i class="calendar icon"></i>
										<input type="text" placeholder="Date" />
									</div>
								</sm-calendar>
							</div>
							<div class="eight wide field">
								<label>Valid To</label>
								<sm-calendar class="ui calendar"
									[settings]="calendarSettings"
									[(value)]="client.validTo"
									[minValue]="client.validFrom">
									<div class="ui fluid input left icon">
										<i class="calendar icon"></i>
										<input type="text" placeholder="Date" />
									</div>
								</sm-calendar>
							</div>
						</div>

						<div class="field" app-field [error]="validation | path: 'whitelistIp'">
							<label>Whitelist IP</label>
							<input type="text" [(ngModel)]="client.whitelistIp" app-trim />
							<small>You can specify a list of IPs by separating them through semicolons.</small>
						</div>

						<div class="field" app-field
							*ngIf="client.type == 1 || client.type == 2"
							[error]="validation | path: 'posLocationId'">
							<label>POS Location</label>

							<sm-dropdown class="ui fluid search selection dropdown label"
								[(value)]="this.client.posLocationId"
								[options]="posLocations">
								<div class="text"></div>
								<i class="dropdown icon"></i>
							</sm-dropdown>
						</div>

						<div class="field" app-field
							*ngIf="client.type == 2"
							[error]="validation | path: 'allowUserImpersonation'">
							<div class="ui checkbox">
								<input type="checkbox" [(ngModel)]="client.allowUserImpersonation" />
								<label>
									<strong>Allow User Impersonation</strong>
								</label>
							</div>
						</div>

						<div class="field" app-field
							*ngIf="client.type == 2 && client.allowUserImpersonation"
							[error]="validation | path: 'registerUrl'">
							<label>Register URL</label>
							<input type="text" [(ngModel)]="client.registerUrl" app-trim />
						</div>

						<div class="field" app-field
							*ngIf="client.type == 2  && client.allowUserImpersonation"
							[error]="validation | path: 'loginCallbackUrl'">
							<label>Login Callback URL</label>
							<input type="text" [(ngModel)]="client.loginCallbackUrl" app-trim />
						</div>
					</div>
				</div>
				<div class="five wide column"
					*ngIf="client.type == 2">
					<h4 class="ui header">
						<i class="user lock icon"></i>
						<div class="content">
							Access Level
							<div class="sub header">Define permissions for client</div>
						</div>
					</h4>

					<div style="max-height: 550px; overflow-y: auto;">
						<ng-template
							[ngTemplateOutlet]="apiTree"
							[ngTemplateOutletContext]="{ permissions: clientPermissions.children }">
						</ng-template>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="sixteen wide column">
					<app-error-message [errors]="errors"></app-error-message>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="upsertModal.toggle()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="updateClient()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal #confirmModal [isTiny]="true">
	<ng-container header>
		Confirm
	</ng-container>

	<ng-container content>
		<p>{{confirmMessage}}</p>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="confirmModal.toggle()">
			No
			<i class="cancel icon"></i>
		</button>
		<button class="ui green right labeled icon button"
			(click)="confirm('delete-client')">
			Yes
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, ColumnSortation, PagedQueryParameter, PaginationModel, TableColumn } from '@nstep-common/core';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import {
	ExportModel,
	ExportModelValidator,
	Headquarter,
	HeadquarterExtendedModel,
	HeadquarterMatrixVersionModel,
	HeadquarterMatrixVersionModelValidator,
	MatrixVersionHistoryModel,
	PosLocation,
	PosLocationGroup
} from '@nstep-internal/pages';
import { ExportTransactionsService, HeadquarterService, SystemService } from '@nstep-internal/shared';
import { ValidationErrors } from 'fluentvalidation-ts';

@Component({
	selector: 'app-export-transactions',
	templateUrl: './export-transactions.component.html'
})
export class ExportComponent extends BaseComponent implements OnInit {
	@ViewChild('exportMatrixModal') exportMatrixModal!: ModalComponent;

	tableData: any[] = [];
	tableDataReady = true;

	headquartersData: HeadquarterExtendedModel[] = [];
	headquartersDataReady = true;

	matrixVersionsData: MatrixVersionHistoryModel[] = [];

	loading = false;
	modalIsLoading = false;
	validExportMatrix = false;
	validExportModel = false;

	headquartersDropdownValues: DropdownOption[] = [];
	matrixVersionsDropdownValues: DropdownOption[] = [];

	headquarters!: Headquarter[];
	posLocationsGroups!: PosLocationGroup[];

	errors: string[] = [];

	allHeadquarters = false;
	allPosLocations = false;

	minDate = new Date('01/01/2014');
	today = new Date(new Date().toDateString());

	calendarSettings = {
		type: 'date',
		today: true,
		minDate: this.minDate,
		maxDate: this.today
	};

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: 'fileName',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	tableColumns: TableColumn[] = [
		{ name: 'Export File Name', key: 'fileName', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Export Date', key: 'date', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Size (kB)', key: 'size', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Status', key: 'status', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Duration (ms)', key: 'duration', isCellCentered: true, isHeaderCentered: true }
	];

	exportMatrixModel: HeadquarterMatrixVersionModel = createProxy(new HeadquarterMatrixVersionModel(), {
		set: () => this.validateExportMatrixModel(this.exportMatrixModel)
	});

	exportMatrixValidation: ValidationErrors<HeadquarterMatrixVersionModel> = {};

	validateExportMatrixModel(value: HeadquarterMatrixVersionModel): void {
		const validator = new HeadquarterMatrixVersionModelValidator();
		this.exportMatrixValidation = validator.validate(value);
		this.validExportMatrix = Object.keys(this.exportMatrixValidation).length === 0;
	}

	exportModel: ExportModel = createProxy(new ExportModel(), {
		set: () => this.validateExportModel(this.exportModel)
	});

	exportModelValidation: ValidationErrors<ExportModel> = {};

	validateExportModel(value: ExportModel): void {
		const validator = new ExportModelValidator();
		this.exportModelValidation = validator.validate(value);
		this.validExportModel = Object.keys(this.exportModelValidation).length === 0;
	}

	constructor(private systemService: SystemService,
		private headquarterService: HeadquarterService,
		private exportTransactionsService: ExportTransactionsService) {
		super();
	}

	ngOnInit(): void {
		this.exportModel.from = this.minDate;
		this.exportModel.to = this.today;
		this.headquarters = [];
		this.posLocationsGroups = [];

		this.initializeTableData();
		this.getMatrixVersions();
	}

	initializeTableData(): void {
		this.tableDataReady = false;
		this.tableData = [];

		this.subscriptions.push(
			this.systemService.getExportHistory(this.pagedQueryModel).subscribe({
				next: response => {

					this.pagination = response.page;
					this.tableData = response.results;

					this.tableDataReady = true;
				},
				error: () => {
					this.tableDataReady = true;
				}
			})
		);
	}

	getHeadquarters(): void {
		this.subscriptions.push(
			this.headquarterService.getHeadquartersEx()
				.subscribe({
					next: response => {
						this.headquartersData = response;
						this.headquartersDropdownValues = [];

						this.headquartersData.forEach((hq, index) => {

							const headquarterDropdownItem = new DropdownOption({
								name: hq.name,
								value: hq.id
							});

							this.headquartersDropdownValues.push(headquarterDropdownItem);

							if (index === 0) {
								this.exportMatrixModel.headquarterId = hq.id;
								this.onHeadquartersValueChange();
							}

							const displayHq: Headquarter = {
								id: hq.id,
								name: hq.name,
								isChecked: false,
								disabled: hq.posLocations === null || !hq.posLocations.length ? true : false
							};

							this.headquarters.push(displayHq);
						});

						this.headquartersDataReady = true;
					},
					error: () => {
						this.headquartersDataReady = true;
					}
				})
		);
	}

	getMatrixVersions(): void {
		this.headquartersDataReady = false;

		this.subscriptions.push(
			this.exportTransactionsService.getMatrixVersions().subscribe({
				next: response => {
					this.matrixVersionsData = response;

					this.getHeadquarters();
				}
			})
		);
	}

	onHeadquartersValueChange(): void {
		this.exportMatrixModel.matrixVersionId = null;

		this.matrixVersionsDropdownValues = [];

		const matrixVersionsDopdownItems = this.matrixVersionsData
			.filter(matrixVersion => matrixVersion.headquarterId === this.exportMatrixModel.headquarterId)
			.map(matrixVersion => {

				return new DropdownOption({
					name: matrixVersion.matrixVersionDisplayName,
					value: matrixVersion.matrixVersionId
				});
			});

		this.matrixVersionsDropdownValues = matrixVersionsDopdownItems;
	}

	save(): void {
		this.modalIsLoading = true;

		this.subscriptions.push(
			this.systemService.exportMatrix(this.exportMatrixModel.matrixVersionId!).subscribe({
				next: response => {
					const blob: Blob = response.body as Blob;
					const fileName = response.headers.get('Content-Disposition')?.replace(/['"]+/g, '').split(';')[1].trim().split('=')[1];

					const anchor = document.createElement('a');
					anchor.download = fileName;
					anchor.href = window.URL.createObjectURL(blob);
					anchor.click();

					this.exportMatrixModal.toggle();
					toast('Success', 'File exported successfully!', 'green');

					this.modalIsLoading = false;
				},
				error: () => {
					this.modalIsLoading = false;
					toast('Error', 'Failed to export file!', 'red');
				}
			})
		);
	}

	exportTransactions(): void {
		const posLocations = this.posLocationsGroups.flatMap(pl => pl.posLocations);

		if (!posLocations.length || !posLocations.some(pl => pl.isChecked)) {
			toast('Warning', 'At least one POS Location must be selected!', 'orange');
			return;
		}

		this.loading = true;

		const posLocationsIds = posLocations.filter(pl => pl.isChecked).flatMap(pl => pl.id);

		this.subscriptions.push(
			this.exportTransactionsService.exportTransactions(this.exportModel.from, this.exportModel.to, posLocationsIds).subscribe({
				next: response => {
					const blob: Blob = response.body as Blob;
					const fileName = response.headers.get('Content-Disposition')?.replace(/['"]+/g, '').split(';')[1].trim().split('=')[1];

					const anchor = document.createElement('a');
					anchor.download = fileName;
					anchor.href = window.URL.createObjectURL(blob);
					anchor.click();

					this.loading = false;
					toast('Success', 'File exported successfully!', 'green');

					this.initializeTableData();
				},
				error: () => {
					this.loading = false;
					toast('Error', 'Failed to export file!', 'red');
				}
			})
		);
	}

	onAllHeadquartersValueChange(value: boolean): void {
		this.posLocationsGroups = [];

		this.headquarters.forEach(hq => {
			hq.isChecked = hq.disabled ? false : value;

			if (value) {

				const headquarter = this.headquartersData.find(hqData => hqData.id === hq.id);

				if (!headquarter) {
					throw 'Headquarter not found.';
				}

				if (!headquarter.posLocations || !headquarter.posLocations.length) {
					return;
				}

				const headquarterPosLocations = headquarter.posLocations.map(hqItem => { return { id: hqItem.id, name: hqItem.name, isChecked: false } as PosLocation; });

				const group: PosLocationGroup = {
					headquarterId: hq.id,
					headquarterName: hq.name,
					isChecked: false,
					posLocations: headquarterPosLocations
				};

				this.posLocationsGroups.push(group);
			}
		});

		this.allPosLocations = false;
	}

	onHeadquarterValueChange(headquarter: Headquarter): void {

		if (headquarter.isChecked) {

			const currentHeadquarter = this.headquartersData.find(hqData => hqData.id === headquarter.id);

			if (!currentHeadquarter) {
				throw 'Headquarter not found.';
			}

			if (!currentHeadquarter.posLocations || !currentHeadquarter.posLocations.length) {
				return;
			}

			const headquarterPosLocations = currentHeadquarter.posLocations.map(hqItem => { return { id: hqItem.id, name: hqItem.name, isChecked: false } as PosLocation; });

			const group: PosLocationGroup = {
				headquarterId: headquarter.id,
				headquarterName: headquarter.name,
				isChecked: false,
				posLocations: headquarterPosLocations
			};

			this.posLocationsGroups.push(group);

		} else {
			const itemToRemoveIndex = this.posLocationsGroups.findIndex(group => group.headquarterId === headquarter.id);

			if (itemToRemoveIndex === -1) {
				return;
			}

			this.posLocationsGroups.splice(itemToRemoveIndex, 1);
		}

		this.allHeadquarters = this.headquarters.filter(hq => !hq.disabled).every(hq => hq.isChecked);
		this.allPosLocations = this.posLocationsGroups.every(hq => hq.isChecked);
	}


	onAllPosLocationsValueChange(value: boolean): void {
		this.posLocationsGroups.forEach(group => {
			group.isChecked = value;
			group.posLocations.forEach(posLocation => {
				posLocation.isChecked = value;
			});
		});
	}

	onPosLocationGroupValueChange(group: PosLocationGroup): void {
		group.posLocations.forEach(posLocation => {
			posLocation.isChecked = group.isChecked;
		});

		this.allPosLocations = this.posLocationsGroups.every(hq => hq.isChecked);
	}

	onPosLocationValueChange(group: PosLocationGroup): void {
		group.isChecked = group.posLocations.every(posLocation => posLocation.isChecked);
		this.allPosLocations = this.posLocationsGroups.every(hq => hq.isChecked);
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.initializeTableData();
	}

	sortChange(col: ColumnSortation): void {
		this.pagedQueryModel.page = 1;
		this.pagedQueryModel.orderField = col.sortAsc !== null ? `${col.key} ${col.sortAsc ? 'asc' : 'desc'}` : '';

		this.tableDataReady = false;
		this.tableData = [];

		this.initializeTableData();
	}
}

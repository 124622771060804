<h3 class="ui header">
	<i class="table icon"></i>
	<div class="content">
		Entitlement Matrix Administration
		<div class="sub header">Entitlements</div>
	</div>
</h3>
<br />

<ng-container>
	<div class="ui form">
		<div class="fields">
			<div class="two wide field">
				<label>Headquarter</label>
				<input type="text" [ngModel]="dataPropagation.headquarterName" [readonly]="true" app-trim>
			</div>
			<div class="four wide field">
				<label>Active Matrix Version</label>
				<input type="text" [ngModel]="activeMatrixVersion" [readonly]="true" app-trim>
			</div>

			<div class="ten wide field">
				<label>Compare to Matrix Version*</label>
				<div class="fields">
					<div class="six wide field">
						<sm-dropdown class="ui selection dropdown"
							[class.disabled]="!tableDataReady"
							[(value)]="selectedPreviousMatrixVersionId"
							[options]="previousMatrixVersionsDropdownValues"
							(valueChange)="onPreviousMatrixVersionValueChange()">
							<div class="text"></div>
							<i class="dropdown icon"></i>
						</sm-dropdown>
					</div>
					<div class="field">
						<button class="ui basic button"
							[disabled]="!selectedPreviousMatrixVersionId"
							(click)="compareMatrixVersions()">
							<i class="code icon"></i>
							Compare
						</button>
						<button *ngIf="!dataPropagation.matrixVersionExpiresToday" class="ui basic button"
							[disabled]="!tableDataReady"
							(click)="reset()">
							<i class="sync icon"></i>
							Reset
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="ui divider"></div>
</ng-container>

<div class="ui stackable grid">
	<div class="row">
		<div class="column">
			<button *ngIf="!dataPropagation.matrixVersionExpiresToday" class="ui basic button"
				[disabled]="!tableDataReady"
				(click)="openAddMatrixAdministrationCodeModal()">
				<i class="cubes icon"></i>
				Add Entitltment Code
			</button>

			<button *ngIf="!dataPropagation.matrixVersionExpiresToday" class="ui basic button"
				[disabled]="!tableDataReady"
				(click)="this.associationTypeCopyItems = []; typeAssociationModal.openAddTypeAssociationModal({}, true)">
				<i class="cube icon"></i>
				Add Type Association
			</button>

			<button *ngIf="!dataPropagation.matrixVersionExpiresToday" class="ui primary right floated right labeled icon button"
				[disabled]="!tableDataReady || !validAmounts"
				(click)="save()">
				Save
				<i class="save icon"></i>
			</button>

			<button class="ui basic right floated right labeled icon button"
				[disabled]="!tableDataReady"
				(click)="showCancelChangesConfirmation()">
				Cancel
				<i class="cancel icon"></i>
			</button>
		</div>
	</div>

	<div class="row" oncontextmenu="return false;">
		<div class="column">
			<div class="ui form">
				<div class="ui long scrolling fluid container" app-loading [enabled]="!tableDataReady"
					[style.max-height]="tableSize">
					<table class="ui first head stuck unstackable celled table">
						<thead>
							<tr *ngIf="(comparisonColumns.activeMatrix | json) === '{}'">
								<th style="min-width: 100px;"></th>
								<th style="text-align: center; min-width: 150px;"></th>
							</tr>

							<tr *ngIf="(comparisonColumns.activeMatrix | json) !== '{}'">
								<th style="min-width: 100px;"></th>

								<th *ngFor="let column of comparisonColumns.activeMatrix | keyvalue" style="text-align: center; min-width: 150px;"
									[ngStyle]="{'background': !column.value.id && !column.value.deleted ? '#edf2e9' : column.value.deleted ? '#fdf6f6' : null}"
									(contextmenu)="!dataPropagation.matrixVersionExpiresToday && !column.value.deleted ? openContextMenu($event, column) :  null">

									<div style="display: inline-block;">
										<a *ngIf="column.value.entitlementMeasurementUnitAssociationId && !dataPropagation.matrixVersionExpiresToday" href="javascript:void(0);"
											title="Edit type association"
											(click)="this.associationTypeCopyItems = []; typeAssociationModal.openAddTypeAssociationModal(column.value, true)">
											{{column.key}}
										</a>
										<ng-container *ngIf="!column.value.entitlementMeasurementUnitAssociationId || dataPropagation.matrixVersionExpiresToday">
											{{column.key}}
										</ng-container>
									</div>

									<div *ngIf="column.value.period">
										<span>({{column.value.period}})</span>
									</div>
									<div *ngIf="comparisonColumns.previousMatrix[column.value.entitlementTypeName] && comparisonColumns.previousMatrix[column.value.entitlementTypeName].period">
										<span class="ui inverted orange text">({{comparisonColumns.previousMatrix[column.value.entitlementTypeName].period}})</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngIf="(comparisonColumns.activeMatrix | json) === '{}' && isEmptyMatrix">
								<td style="min-width: 100px;"></td>
								<td style="text-align: center; min-width: 150px;">-</td>
							</tr>

							<tr *ngIf="(comparisonColumns.activeMatrix | json) !== '{}' && isEmptyMatrix">
								<td style="min-width: 100px;"></td>
								<td *ngFor="let column of comparisonColumns.activeMatrix | keyvalue" style="text-align: center; min-width: 150px;">-</td>
							</tr>

							<ng-container *ngFor="let item of entitlementMatrixAdministrationTableData">
								<tr *ngIf="item.activeMatrixVersion" [class.positive]="item.activeMatrixVersion.isNew">

									<td (contextmenu)="!dataPropagation.matrixVersionExpiresToday ? openContextMenu($event, item, true) : null">
										<a *ngIf="item.previousMatrixVersion && item.previousMatrixVersion.expanded === true" href="javascript:void(0);" title="Collapse">
											<i class="chevron up icon" (click)="item.previousMatrixVersion.expanded = false"></i>
										</a>
										<a *ngIf="item.previousMatrixVersion && item.previousMatrixVersion.expanded === false" href="javascript:void(0);" title="Expand">
											<i class="chevron down icon" (click)="item.previousMatrixVersion.expanded = true"></i>
										</a>

										<div [title]="item.activeMatrixVersion.entitlementCode.description" style="display: inline-block;">{{item.key}}</div>
									</td>

									<td *ngFor="let cell of item.activeMatrixVersion.cells" style="text-align: center;"
										[class.error]="cell.isDeleted"
										[class.warning]="!cell.isNew && !cell.isDeleted && cell.isDifferent"
										[class.positive]="cell.isNew">
										<div class="field" app-field [error]="amountValidations[cell.validationId] | path: 'amount'">

											<ng-container *ngIf="!cell.asPlaceholder">
												<input style="width: 17px; height: 17px;" [ngStyle]="{'vertical-align': cell.rationingTypeId === 1 ? '-webkit-baseline-middle' : 'baseline'}"
													type="checkbox"
													[disabled]="dataPropagation.matrixVersionExpiresToday"
													[(ngModel)]="cell.checked"
													(change)="onCellValueChange(item, cell)">

												<!--[disabled]="item.activeMatrixVersion.entitlementCode.isDependent && !dataPropagation.generateDependentRations"-->
												<input *ngIf="cell.rationingTypeId === 1" style="margin-left: 7px; width: 85px;"
													type="number"
													min="0"
													(focus)="onFocus(cell)"
													(blur)="onBlur(cell)"
													[disabled]="dataPropagation.matrixVersionExpiresToday || item.activeMatrixVersion.entitlementCode.isDependent && !dataPropagation.generateDependentRations"
													[(ngModel)]="cell.amount"
													(input)="cell.checked = true; onCellValueChange(item, cell)">
											</ng-container>

										</div>
									</td>
								</tr>

								<tr *ngIf="item.previousMatrixVersion && (!item.activeMatrixVersion || item.previousMatrixVersion.expanded)"
									[class.error]="!item.activeMatrixVersion">
									<td>
										<a *ngIf="!item.activeMatrixVersion && !dataPropagation.matrixVersionExpiresToday && item.previousMatrixVersion.canBeAddedToActiveMatrix"
											href="javascript:void(0);"
											title="Add to active matrix version">
											<!--<i class="plus icon" (click)="addToCurrentMatrixVersion(item)"></i>-->
										</a>
										<div style="display: inline-block; opacity: 0.45;"
											[title]="item.previousMatrixVersion.entitlementCode.description">
											{{item.key}}
										</div>
									</td>

									<!--[class.warning]="!cell.asPlaceholder && item.activeMatrixVersion && cell.isDifferent"-->
									<td *ngFor="let cell of item.previousMatrixVersion.cells" style="text-align: center;"
										[class.warning]="item.activeMatrixVersion && cell.isDifferent">

										<ng-container *ngIf="!cell.asPlaceholder">
											<input style="width: 17px; height: 17px; opacity: 0.45;"
												[ngStyle]="{'vertical-align': cell.rationingTypeId === 1 ? '-webkit-baseline-middle' : 'baseline'}"
												type="checkbox"
												[ngModel]="cell.checked"
												[disabled]="true">

											<input *ngIf="cell.rationingTypeId === 1" style="margin-left: 7px; width: 85px; opacity: 0.45;"
												type="number"
												[disabled]="true"
												[ngModel]="cell.amount">
										</ng-container>
									</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</div>
			</div>

		</div>
	</div>
</div>

<sm-modal #addMatrixAdministrationCodeModal [isLoading]="modalIsLoading" [isTiny]="true">
	<ng-container header>Add Entitlement Code</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="validation | path: 'entitlementCodeId'">
				<label>Code*</label>
				<sm-dropdown class="ui selection dropdown"
					[(value)]="matrixAdministrationModel.entitlementCodeId"
					[options]="entitlementCodesDropdownValues"
					(valueChange)="onEntitlementCodeValueChange()">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>

			<div class="field" app-field [error]="validation | path: 'entitlementCodeGroup'">
				<label>Group*</label>
				<input type="text" [(ngModel)]="matrixAdministrationModel.entitlementCodeGroup" app-trim>
			</div>

			<div class="field" app-field [error]="validation | path: 'entitlementCodeDescription'">
				<label>Description*</label>
				<textarea style="height: 135px; resize: none;" [(ngModel)]="matrixAdministrationModel.entitlementCodeDescription" app-trim></textarea>
			</div>

			<h4>All fields marked with * are mandatory</h4>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="addMatrixAdministrationCodeModal.toggle()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="addEntitlementCode()">
			Add
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>

<app-type-association-modal [headquarterId]="dataPropagation.headquarterId"
	[matrixVersionId]="dataPropagation.selectedMatrixVersionId"
	[existingEntitlementTypeIds]="existingEntitlementTypeIds"
	[tableData]="entitlementMatrixAdministrationTableData"
	(addColumn)="addColumn($event)"
	(refreshTableData)="refreshAdministerMatrixDataForCurrentState()">
</app-type-association-modal>

<app-context-menu *ngIf="contextMenuItems.length && shownContextMenu" [contextMenuItems]="contextMenuItems"
	[contextMenuStyle]="contextMenuStyle"
	(contextMenuItemClick)="onContextMenuItemClick($event)">
</app-context-menu>

<sm-modal #matrixAdministrationConfirmationModal [isLoading]="modalIsLoading" [isTiny]="true">
	<ng-container *ngIf="currentConfirmationType === 'CancelChanges'" header>Cancel Changes</ng-container>
	<ng-container *ngIf="currentConfirmationType === 'DeleteColumn'" header>Delete entitlement type: {{contextMenuSelectedItem!.item.key}}</ng-container>
	<ng-container *ngIf="currentConfirmationType === 'DeleteRow'" header>Delete entitlement code: {{contextMenuSelectedItem!.item.key}}</ng-container>

	<ng-container *ngIf="currentConfirmationType === 'CancelChanges'" content>
		<p>
			You will be redirected to the Entitlement Matrix Page and all your changes will be removed!
			<br /><br />
			Are you sure you want to proceed?
		</p>
	</ng-container>

	<ng-container *ngIf="currentConfirmationType === 'DeleteColumn'" content>
		<p>Are you sure you want to delete entitlement type <b>{{contextMenuSelectedItem!.item.key}}</b> from the matrix?</p>
		<div class="ui grid">
			<div class="two wide column">
				<i class="huge red exclamation triangle icon"></i>
			</div>
			<div class="fourteen wide column">
				<p><span class="ui inverted red text">Warning!</span> If you remove this entitlement type, it will be deleted from all entitlement codes that use it in the current matrix!</p>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="currentConfirmationType === 'DeleteRow'" content>
		<div class="ui grid">
			<div class="row">
				<div class="three wide column" style="align-content:center; text-align: center;">
					<i class="huge red exclamation triangle icon"></i>
				</div>
				<div class="thirteen wide column">
					<p>Are you sure you want to delete entitlement code <b>{{contextMenuSelectedItem!.item.key}}</b> from the matrix?</p>
					<p *ngIf="!impactedActiveCardsBatches.length">
						<span class="ui inverted red text">Warning!</span> If you remove this entitlement code, all the information about associated entitlement types will be deleted!
					</p>
					<p *ngIf="impactedActiveCardsBatches.length">
						<span class="ui inverted red text">Warning!</span> If you remove this entitlement code, all the information about associated entitlement types will be deleted and the following active cards will be impacted!
					</p>
				</div>
			</div>

			<div *ngIf="impactedActiveCardsBatches.length" class="ui divider"></div>
		</div>

		<div *ngIf="impactedActiveCardsBatches.length" class="ui grid" style="max-height: 510px; overflow-y: auto;">
			<div *ngFor="let batch of impactedActiveCardsBatches" class="three column centered row">
				<div *ngFor="let column of batch" class="column">
					<div class="ui raised segment">
						<div class="ui bulleted list">
							<div *ngFor="let card of column" class="item">{{card}}</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="matrixAdministrationConfirmationModal.toggle()">
			{{ currentConfirmationType === 'CancelChanges' ? 'No': 'Cancel'}}
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="currentConfirmationType === 'DeleteColumn' ? deleteType(contextMenuSelectedItem!.item) : 
				currentConfirmationType === 'DeleteRow' ? deleteCode(contextMenuSelectedItem!.item) : cancelChanges()">
			{{ currentConfirmationType === 'CancelChanges' ? 'Yes': 'Delete'}}
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>
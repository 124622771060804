<h3 class="ui header">
	<i class="file upload icon"></i>
	<div class="content">
		Import History
		<div class="sub header">System</div>
	</div>
</h3>
<br />

<div class="ui from">
	<div class="field">
		<app-table-view
			[itemTemplate]="importHystoryItemTemplate"
			[data]="tableData"
			[dataReady]="tableDataReady"
			[columns]="tableColumns"
			[noSearch]="true"
			[useServerPagination]="true"
			[pageSize]="pagination.pageSize"
			[currentPage]="pagination.currentPage"
			[totalPages]="pagination.totalPages"
			(pageChangeEvent)="pageChange($event)"
			(sortChangeEvent)="sortChange($event)">
		</app-table-view>
	</div>
</div>

<ng-template #importHystoryItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>{{item.fileName | dash}}</td>
		<td>{{item.date | date: 'dd/MM/yyyy - h:mm a' | dash}}</td>
		<td>{{item.isManual ? 'Manually' : 'Automatically'}}</td>
		<td>{{item.size | dash}}</td>
		<td>{{item.status ? 'Completed' : 'Failed'}}</td>
		<td>{{item.duration | dash}}</td>
	</tr>
</ng-template>
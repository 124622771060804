import { JsonProperty } from "@nstep-common/utils";

export class BulkAdvanceSettings {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: Date })
	createDate = new Date();

	@JsonProperty({ type: Number })
	month = this.createDate.getMonth() + 2;

	@JsonProperty({ type: Number })
	issueMonth = this.createDate.getMonth() + 1;

	@JsonProperty({ type: Boolean })
	enabled = true;

	constructor(data: Partial<BulkAdvanceSettings> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

// I have replaced the old model that extends BulkAdvanceSettings because the mapper throws error if the model from the FE does not match the one from the BE
// Obs: The BulkAdvanceAmisSettingsDto from BE does not extend another object
export class BulkAdvanceAmisSettings {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	amisCards: string = '';

	@JsonProperty({ type: String })
	amisSearchTerm: string = '';

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: Date })
	createDate = new Date();

	@JsonProperty({ type: Number })
	month = this.createDate.getMonth() + 2;

	@JsonProperty({ type: Number })
	issueMonth = this.createDate.getMonth() + 1;

	@JsonProperty({ type: Boolean })
	enabled = true;

	constructor(data: Partial<BulkAdvanceAmisSettings> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}
<sm-modal #addTypeAssociationModal [isLoading]="isLoading" [isTiny]="true">
	<ng-container *ngIf="!isEdit" header>Add Entitlement Type Association</ng-container>
	<ng-container *ngIf="isEdit" header>Modify Entitlement Type Association: {{selectedEntitlementType.entitlementTypeName}}</ng-container>

	<ng-container content>
		<div class="ui form">
			<div *ngIf="!isEdit" class="field" app-field [error]="validation | path: 'entitlementTypeId'">
				<label>Entitlement Type*</label>
				<sm-dropdown class="ui selection dropdown"
					[(value)]="typeAssociationModel.entitlementTypeId"
					[options]="typeAssociationsDropdownValues"
					(valueChange)="onTypeAssociationValueChange()">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>

			<ng-container *ngIf="selectedEnabledType.rationingTypeId === 1 || isEdit">

				<ng-container *ngIf="!fromAdministerView || isEdit">
					<div class="field" app-field [error]="validation | path: 'issuanceTypeId'">
						<label>Issuance Type*</label>
						<sm-dropdown class="ui selection dropdown"
							[(value)]="typeAssociationModel.issuanceTypeId"
							[options]="issuanceTypesDropdownValues"
							(valueChange)="onIssuanceTypeValueChange()">
							<div class="text"></div>
							<i class="dropdown icon"></i>
						</sm-dropdown>
					</div>

					<div class="field">
						<div class="ui checkbox">
							<input type="checkbox" id="typeAssociationIsBuyable" [(ngModel)]="typeAssociationModel.isBuyable">
							<label for="typeAssociationIsBuyable">Can be bought</label>
						</div>
					</div>

					<div *ngIf="typeAssociationModel.issuanceTypeId === 2" class="field">
						<div class="ui checkbox">
							<input type="checkbox" id="typeAssociationIsNotProRated" [(ngModel)]="typeAssociationModel.isNotProRated">
							<label for="typeAssociationIsNotProRated">Is Not Pro-Rated</label>
						</div>
					</div>

					<div *ngIf="typeAssociationModel.issuanceTypeId === 1" class="field" app-field [error]="validation | path: 'periodCycle'">
						<label>Period (Days)*</label>
						<input type="number" min="0" max="999" [(ngModel)]="typeAssociationModel.periodCycle">
					</div>

					<div *ngIf="typeAssociationModel.issuanceTypeId === 2" class="field" app-field [error]="validation | path: 'periodCycle'">
						<label>Period (Days)*</label>
						<sm-dropdown class="ui selection dropdown"
							[(value)]="typeAssociationModel.periodCycle"
							[options]="periodCyclesDropdownValues">
							<div class="text"></div>
							<i class="dropdown icon"></i>
						</sm-dropdown>
					</div>

					<div *ngIf="typeAssociationModel.issuanceTypeId === 1" class="two fields">
						<div class="field" app-field [error]="validation | path: 'periodsUntilExpiry'">
							<label># Periods Until Expiry*</label>
							<input type="number" min="0" max="999" [(ngModel)]="typeAssociationModel.periodsUntilExpiry">
						</div>

						<div class="field" app-field [error]="validation | path: 'periodsIssuedUpfront'">
							<label># Periods Issued Upfront*</label>
							<input type="number" min="0" max="999" [(ngModel)]="typeAssociationModel.periodsIssuedUpfront">
						</div>
					</div>

					<div *ngIf="typeAssociationModel.issuanceTypeId !== null && typeAssociationModel.issuanceTypeId !== 3">
						<div class="two fields">
							<div class="field">
								<label>Start Date</label>
								<sm-calendar class="ui calendar"
									[settings]="calendarSettings"
									[(value)]="typeAssociationModel.typeAssociationStartDate"
									[maxValue]="typeAssociationModel.typeAssociationEndDate">
									<div class="ui fluid input left icon">
										<i class="calendar icon"></i>
										<input type="text" placeholder="Date" />
									</div>
								</sm-calendar>
							</div>

							<div class="field">
								<label>End Date</label>
								<sm-calendar class="ui calendar"
									[settings]="calendarSettings"
									[(value)]="typeAssociationModel.typeAssociationEndDate"
									[minValue]="typeAssociationModel.typeAssociationStartDate">
									<div class="ui fluid input left icon">
										<i class="calendar icon"></i>
										<input type="text" placeholder="Date" />
									</div>
								</sm-calendar>
							</div>
						</div>
					</div>
				</ng-container>

				<div class="field" app-field [error]="validation | path: 'measurementUnitId'">
					<label>Unit of Measure*</label>
					<sm-dropdown class="ui selection dropdown"
						[(value)]="typeAssociationModel.measurementUnitId"
						[options]="measurementUnitsDropdownValues">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field" app-field [error]="validation | path: 'unitDescription'">
					<label>Unit Description*</label>
					<textarea style="height: 135px; resize: none;" [(ngModel)]="typeAssociationModel.unitDescription" app-trim></textarea>
				</div>

			</ng-container>

			<h4>All fields marked with * are mandatory</h4>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="addTypeAssociationModal.toggle()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="!fromAdministerView ? typeAssociationMainSave() : typeAssociationAdministerSave()">
			Save
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>
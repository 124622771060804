import { Injectable } from '@angular/core';
import { Any } from '@nstep-common/utils';
import { ApiService, PagedQueryParameter, PagedResponse } from '@nstep-common/core';
import { EntitlementTypeCreateDto, EntitlementTypeDto, EntitlementTypeModel, RationedType } from '@nstep-internal/pages';
import { Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EntitlementTypeService {

	constructor(private apiService: ApiService) { }

	getEntitlementTypes(model: PagedQueryParameter): Observable<PagedResponse<EntitlementTypeDto>> {
		return this.apiService.getPage([EntitlementTypeDto], 'entitlement-type', model);
	}

	getAllEntitlementTypes(): Observable<EntitlementTypeDto[]> {
		return this.apiService.get([EntitlementTypeDto], 'entitlement-type/all');
	}

	getEntitlementTypesByHq(ids: number[]): Observable<any> {
		return this.apiService.post(Any, 'entitlement-type/grouped', ids);
	}

	getEnabledEntitlementTypes(): Observable<EntitlementTypeDto[]> {
		return this.apiService.get([EntitlementTypeDto], 'entitlement-type/enabled');
	}

	createEntitlementType(model: EntitlementTypeModel): Observable<EntitlementTypeDto> {
		const postModel = new EntitlementTypeCreateDto({
			name: model.name,
			rationingTypeId: model.rationed ? RationedType.Rationed : RationedType.NonRationed,
			minBuyAmount: model.minBuyAmount
		});

		return this.apiService.post(EntitlementTypeDto, 'entitlement-type', postModel);
	}

	updateEntitlementType(model: EntitlementTypeModel): Observable<EntitlementTypeDto> {
		const putModel = new EntitlementTypeCreateDto({
			name: model.name,
			rationingTypeId: model.rationed ? RationedType.Rationed : RationedType.NonRationed,
			minBuyAmount: model.minBuyAmount
		});

		return this.apiService.put(EntitlementTypeDto, `entitlement-type/${model.id}`, putModel);
	}

	enableEntitlementType(id: number): Observable<any> {
		return this.apiService.patchNoContent(`entitlement-type/${id}`)
			.pipe(tap(() => this.apiService.invalidateCache('entitlement-type/enabled')));
	}

	disableEntitlementType(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`entitlement-type/${id}`)
			.pipe(tap(() => {
				this.apiService.invalidatePartialMatchCache('entitlement-assignment');
				this.apiService.invalidateCache('entitlement-type/enabled');
			}));
	}

	clearGetEntitlementTypesCache(): void {
		this.apiService.invalidateCache('entitlement-type');
	}
}

import { JsonProperty } from '@nstep-common/utils';
import { HeadquarterWithNationDto, NationDto } from '@nstep-internal/pages';

export class PosLocationInformationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Boolean })
	isFastScan!: boolean;

	@JsonProperty({ type: String })
	postalCode!: string;

	@JsonProperty({ type: String })
	streetName!: string;

	@JsonProperty({ type: String, nullable: true })
	telephone: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	template: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	buildingNumber: string | null = null;

	@JsonProperty({ type: String })
	city!: string;

	@JsonProperty({ type: Number, nullable: true })
	nationId: number | null = null;

	@JsonProperty({ type: String })
	openingHour!: string;

	@JsonProperty({ type: String })
	closingHour!: string;

	@JsonProperty({ type: Boolean })
	enabled!: boolean;

	@JsonProperty({ type: NationDto, nullable: true })
	nation: NationDto | null = null;

	constructor(data: Partial<PosLocationInformationDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class PosLocationType {
	id!: number;
	name: string | null = null;
	isChecked!: boolean;

	constructor(data: PosLocationType | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class PosLocationHeadquarter {
	id!: number;
	name: string | null = null;

	constructor(data: PosLocationHeadquarter | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class HeadquarterAssociation {
	informationId: number | null = null;
	headquarterId!: number;
	headquarterName: string | null = null;
	startDate: Date | null = null;
	endDate: Date | null = null;
	canModify: boolean | null = null;
	isNew: boolean = false;
	isModified: boolean = false;

	constructor(data: Partial<HeadquarterAssociation> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class PosLocationModel {
	id: number | null = null;
	posLocationName!: string;
	countryId: number | null = null;
	telephone: string | null = null;
	streetName!: string;
	buildingNumber: string | null = null;
	postalCode!: string;
	cityOrTown!: string;
	openingHour: Date | null = null;
	localOpeningHour: Date | null = null;
	closingHour: Date | null = null;
	localClosingHour: Date | null = null;
	posLocationTypeIds!: number[];
	headquarterId!: number;
	isFastScan: boolean = false;
	headquarterAssociations!: HeadquarterAssociation[];

	commonDateHolder: Date | null = null;

	entitlementTypeHeadquarterId: number | null = null;
	entitlementTypeId: number | null = null;
	entitlementTypesAssociations!: PosLocationEntitlementTypeAssociation[];

	amisCardNumber: string | null = null;
	amisCardId: number | null = null;
	posOperatorStartDate: Date | null = null;
	posOperatorEndDate: Date | null = null;
	posOperatorsAssociations!: PosOperatorAssociation[];

	displayPOCForm!: boolean;
	noPrimaryContactSelected!: boolean;

	posOperatorContactName!: string | null;
	posOperatorContactEmail!: string | null;
	posOperatorContactPhoneNumber!: string | null;
	isPrimaryPosOperatorContact!: boolean;

	groupPropertiesValidation!: PosLocationSteps;

	constructor(data: Partial<PosLocationModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum PosLocationSteps {
	PosLocationInformation = 1,
	HeadquarterAssociation = 1.1,
	EntitlementTypes = 2,
	AssignEntitlementTypes = 2.1,
	PosOperators = 3,
	PosOperatorContact = 3.1,
	EnrollPosOperator = 3.2,
}

export class PosLocationAssociationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	poslocationInformationId!: number;

	@JsonProperty({ type: String })
	headquarterName!: string;

	@JsonProperty({ type: String })
	nationName!: string;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date })
	endDate!: Date;
}

export enum ChangeDateTypeModal {
	Disable = 'Disable',
	Assign = 'Assign',
	ModifyHqDate = 'ModifyHqDate',
	Revoke = 'Revoke',
	ModifyPosOperatorDate = 'ModifyPosOperatorDate',
	Unenroll = 'Unenroll'
}

export class EntitlementTypeDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Number })
	rationingTypeId!: number;

	@JsonProperty({ type: Boolean })
	disabled!: boolean;

	@JsonProperty({ type: Number })
	minBuyAmount!: number;
}

export class PosTypeAssociationDto {
	@JsonProperty({ type: Number })
	entitlementTypeId!: number;

	@JsonProperty({ type: Number })
	posLocationId!: number;

	@JsonProperty({ type: EntitlementTypeDto })
	entitlementType!: EntitlementTypeDto;

	@JsonProperty({ type: HeadquarterWithNationDto })
	headquarter!: HeadquarterWithNationDto;
}

export class PosLocationEntitlementTypeAssociation {
	posLocationId: number | null = null;
	entitlementTypeId!: number;
	entitlementTypeName!: string;
	headquarterId!: number;
	headquarterName!: string;
	rationingTypeId!: number;
	disabled!: boolean;

	constructor(data: Partial<PosLocationEntitlementTypeAssociation> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class PosoperatorContactDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: String })
	email!: string;

	@JsonProperty({ type: String })
	phoneNumber!: string;

	@JsonProperty({ type: Date })
	timeStamp!: Date;

	@JsonProperty({ type: Boolean })
	isPrimary!: boolean;

	constructor(data: Partial<PosoperatorContactDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class PosOperatorDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String, nullable: true })
	amiscard!: string | null;

	@JsonProperty({ type: Number })
	amiscardId!: number;

	@JsonProperty({ type: Boolean })
	isFastScan!: boolean;

	@JsonProperty({ type: Number })
	poslocationId!: number;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date, nullable: true })
	endDate: Date | null = null;

	@JsonProperty({ type: Date, nullable: true })
	lastModifiedOn: Date | null = null;

	@JsonProperty({ type: PosoperatorContactDto, nullable: true })
	contact: PosoperatorContactDto | null = null

	constructor(data: Partial<PosOperatorDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class PosOperatorAssociation {
	id: number | null = null;
	poslocationId: number | null = null;
	amisCardNumber: string | null = null;
	amisCardId!: number;
	startDate!: Date;
	endDate: Date | null = null;
	endDateInThePast: boolean = false;
	canModify: boolean | null = null;
	isNew: boolean = false;
	isModified: boolean = false;
	contact!: PosoperatorContactDto | null;
	contactDeleted: boolean = false;
	isActive: boolean = false;
	isFastScan: boolean = false;

	constructor(data: Partial<PosOperatorAssociation> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class AmisCardDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: Date })
	startDate!: Date;
}

export class PosLocationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date })
	endDate!: Date;

	@JsonProperty({ type: Number })
	poslocationInformationId!: number;

	@JsonProperty({ type: Date })
	modifiedDate!: Date;
}

export class TypeGroupedByHqDto {
	types!: { [key: number]: EntitlementTypeDto[]; };
}

export enum RationedType {
	Rationed = 1,
	NonRationed = 2
}
<h3 class="ui header">
	<i class="wrench icon"></i>
	<div class="content">
		Reports Management
		<div class="sub header">Reports</div>
	</div>
</h3>

<div *ngIf="currentReportView === 0" class="ui stackable grid">
	<div class="row">
		<div class="column">
			<div class="ui icon input">
				<i class="search link icon" (click)="search()"></i>
				<input type="text"
					placeholder="Search..."
					[(ngModel)]="mainPagedQueryModel.searchBy"
					(keydown.enter)="search()"
					app-trim>
			</div>

			<button class="ui right floated primary button"
				(click)="changeView(1)">
				<i class="plus icon"></i>Add Report
			</button>
		</div>
	</div>

	<div app-loading [enabled]="!tableDataReady" class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="reportManagementItemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true"
				[useServerPagination]="true"
				[pageSize]="mainPagination.pageSize"
				[currentPage]="mainPagination.currentPage"
				[totalPages]="mainPagination.totalPages"
				(pageChangeEvent)="mainPageChange($event)">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #reportManagementItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>
			<a href="javascript:void(0);" (click)="onUserAction(item, 'Edit')">{{item.name | dash}}</a>
		</td>
		<td>
			<div *ngFor="let role of item.availableRoles">{{role | dash}}</div>
		</td>
		<td>{{item.country | dash}}</td>
		<td>{{item.description |dash}}</td>
		<td>
			<ng-container *ngFor="let action of item.actions; let isLast=last">
				<a href="javascript:void(0);" (click)="onUserAction(item, action)">{{ action | dash }}</a>
				<ng-container *ngIf="!isLast"> &#47; </ng-container>
			</ng-container>
		</td>
	</tr>
</ng-template>

<ng-container *ngIf="currentReportView === 1 || currentReportView === 2">
	<h4 class="ui header">
		<div class="content">
			{{currentReportView === 1? 'Add Report' : 'Modify Report: ' + selectedTableItem.name}}
		</div>
	</h4>

	<div class="ui divider"></div>

	<div app-loading [enabled]="!reportSettingsDataReady" class="ui form">
		<div class="ui stackable grid">
			<div class="three wide column">
				<div class="field" app-field [error]="validation | path: 'name'">
					<label>Report Name*</label>
					<input type="text" [(ngModel)]="addReportModel.name" app-trim>
				</div>

				<div class="field" app-field [error]="validation | path: 'nationId'">
					<label>Nation*</label>
					<sm-dropdown class="ui selection dropdown"
						[(value)]="addReportModel.nationId"
						[options]="nationsDropdownValues">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<p>All fields marked with * are mandatory</p>
			</div>

			<div class="three wide column">
				<div class="field" app-field [error]="validation | path: 'description'">
					<label>Report Description</label>
					<textarea style="min-height: 85px; height: 114px; resize: none;" [(ngModel)]="addReportModel.description" app-trim></textarea>
				</div>
			</div>

			<div class="five wide column">
				<div class="field" app-field [error]="validation | path: 'availableRolesIds'">
					<label>Available for*</label>
					<div class="ui segment" style="margin: 0; overflow: auto; max-height: 84px;">
						<div class="ui list">
							<div class="item" *ngFor="let role of roles">
								<div class="ui checkbox" style="margin-top: 0px;">
									<input type="checkbox" id="available-role-{{role.roleId}}" [(ngModel)]="role.isChecked" (change)="onAvailableRolesValueChange(role)">
									<label for="available-role-{{role.roleId}}">{{role.roleName}}</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="!selectedTableItem.id" class="field" app-field [error]="validation | path: 'templateId'">
					<label>Report Template*</label>
					<sm-dropdown class="ui selection dropdown"
						[(value)]="addReportModel.templateId"
						[options]="reportTemplatesDropdownValues">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="column">
					<button class="ui basic right labeled icon button"
						(click)="changeView(0)">
						Cancel
						<i class="cancel icon"></i>
					</button>
					<button *ngIf="!selectedTableItem.id" class="ui basic right labeled icon button"
						[disabled]="addReportModel.templateId === null"
						(click)="customizeReport()">
						Customize Report
						<i class="tools icon"></i>
					</button>

					<button class="ui primary right floated right labeled icon button"
						[disabled]="!isValid || !showReportView"
						(click)="save()">
						Save
						<i class="save icon"></i>
					</button>
				</div>
			</div>

			<div *ngIf="showReportView" class="three wide column">
				<div class="field">
					<label>Grid Features</label>
					<div class="ui segment" style="margin: 0; height: 141px;">
						<div class="ui list">
							<div class="item" *ngFor="let feature of gridFeatures | keyvalue; let i = index">
								<div class="ui checkbox" style="margin-top: 0px;">
									<input type="checkbox" id="feature-{{i}}" [(ngModel)]="feature.value.initialValue">
									<label for="feature-{{i}}">{{feature.value.name}}</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<button class="ui basic right labeled icon button"
					(click)="setGridFeatures()">
					Set Grid Features
					<i class="pencil alternate icon"></i>
				</button>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="currentReportView === 2 || showReportView">
	<div class="ui divider"></div>
	<div app-loading [enabled]="loading">
		<app-report-view
			[isCelled]="true"
			[isSortable]="gridFeatures[0].afterSetValue"
			[isFilterable]="gridFeatures[1].afterSetValue"
			[hideUnhideColumns]="gridFeatures[2].afterSetValue"
			[isExportable]="gridFeatures[3].afterSetValue"
			[isDraggable]="gridFeatures[4].afterSetValue"
			[data]="reportData"
			[dataReady]="reportDataReady"
			[columns]="reportColumns"
			[pageSize]="reportsPagination.pageSize"
			[currentPage]="reportsPagination.currentPage"
			[totalPages]="reportsPagination.totalPages"
			(pageChangeEvent)="reportsPageChange($event)"
			(pageSizeChangeEvent)="pageSizeChange($event)"
			(filterChangeEvent)="filterChange($event)"
			(exportExcelEvent)="exportExcelTemplate()"
			(sortByColEvent)="sortByCol($event)">
		</app-report-view>
	</div>

</ng-container>

<sm-modal [isLoading]="modalIsLoading" #reportConfirmationModal [isTiny]="true">
	<ng-container *ngIf="currentReportAction === 'Copy'" header>Copy Report: {{selectedTableItem.name}}</ng-container>
	<ng-container *ngIf="currentReportAction === 'Delete'" header>Delete Report: {{selectedTableItem.name}}</ng-container>

	<ng-container content>
		<p *ngIf="currentReportAction === 'Copy'">Are you sure you want to copy report <b>{{selectedTableItem.name}}</b>?</p>
		<p *ngIf="currentReportAction === 'Delete'">Are you sure you want to delete report <b>{{selectedTableItem.name}}</b>?</p>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="reportConfirmationModal.toggle()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="currentReportAction === 'Copy' ? copyReport(selectedTableItem.id!) : deleteReport(selectedTableItem.id!)">
			{{currentReportAction === 'Copy' ? 'Save' : 'Yes' }}
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>